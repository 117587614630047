import React, {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

import LoadingSpinner from "../components/LoadingSpinner";
import axios from "axios";
import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    USER_PROJECTS_ENDPOINT,
    WORKSHOPS_INFO_ENDPOINT
} from "../api/api";

const CanCreateProject = ({children, user}) => {
    const [activeWorkshop, setActiveWorkshop] = useState(null);
    useEffect(() => {
        axios
                    .get(WORKSHOPS_INFO_ENDPOINT, {
                        params: {is_active: "True"},
                        headers: getJSONAcceptAuthConfig().headers,
                    })
                    .then(response => {
                        setActiveWorkshop((response.data[0]))
                    })
                    .catch(error => {
                        console.error('Error getting active workshop data:', error);
                    })
    }, []);

    const location = useLocation();
    if (user === null || activeWorkshop === null) {
        return <LoadingSpinner/>
    }


    return (!user?.last_name || !user?.first_name || !user?.title || user?.avatar == null || !activeWorkshop?.customers_can_apply && !(user?.is_ws_director || user?.is_lws_director || user?.is_ls_manager))
        ? <Navigate to='/account/project' state={{from: location}} replace={true}/>
        : children;
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(CanCreateProject);