import React, {useState, useEffect} from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";

import {Button} from "@gravity-ui/uikit";

import LoadingSpinner from "../../../components/LoadingSpinner";
import {getAcceptAuthConfig, WORKSHOP_ABOUT_ENDPOINT} from "../../../api/api";
import WorkshopAboutForm from "./WorkshopAboutForm";

function WorkshopAboutUpdate() {
    const {id} = useParams();

    const [submitting, setSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);

    const [about, setAbout] = useState({workshop: id, md_text: ""});

    useEffect(() => {
        axios
            .get(WORKSHOP_ABOUT_ENDPOINT(id), getAcceptAuthConfig())
            .then(response => {
                setAbout(response.data);
                setIsNotFound(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setIsNotFound(true); // Устанавливаем состояние 404
                } else {
                    console.error('Не удалось получить описание мастерской:', error);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        const requestMethod = isNotFound ? 'post' : 'put'; // Выбираем метод запроса в зависимости от состояния

        axios({
            method: requestMethod,
            url: WORKSHOP_ABOUT_ENDPOINT(id),
            data: about,
            ...getAcceptAuthConfig()
        })
            .then(response => {
                setAbout(response.data);
                setIsNotFound(false); // После успешного сохранения считаем, что запись существует
            })
            .catch(error => {
                console.error('Не удалось сохранить описание мастерской:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const onChange = (e) => {
        setAbout((prevAbout) => ({
            ...prevAbout,
            [e.target.name]: e.target.value,
        }));
    }

    return (
        <>
            {/*<h3 className="mb-2">О мастерской</h3>*/}
            {/*<hr className="clearfix w-100 pb-0"/>*/}

            {isLoading &&
                <LoadingSpinner/> ||
                <>
                    <WorkshopAboutForm about={about.md_text} onChange={onChange}
                                       submitting={submitting} onSubmit={onSubmit}/>
                </>
            }
        </>
    );
}

export default WorkshopAboutUpdate;