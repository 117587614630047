import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";

import {Magnifier} from "@gravity-ui/icons";
import {Icon, Label, Select, TextInput} from "@gravity-ui/uikit";

import {
    getAcceptAuthConfig, getJSONConfig, PROJECT_APPLICATIONS_DIRECTOR_ENDPOINT, STREAMS_ENDPOINT, VENUES_ENDPOINT
} from "../../../api/api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {UNALLOCATED_PROJECTS} from "../../../utils/const";
import {getGradeText, getThemeByPriority} from "../../../utils/utils";

function DirectorProjectApplicationsPage() {
    const [isLoading, setIsLoading] = useState(true);

    const [projects, setProjects] = useState([]);
    const [venues, setVenues] = useState([]);
    const [streams, setStreams] = useState([]);
    const [enteredValue, setEnteredValue] = useState("");

    const [venueFilter, setVenueFilter] = useState([]);
    const [streamFilter, setStreamFilter] = useState('');
    const [enteredFilter, setEnteredFilter] = useState('');

    useEffect(() => {
        axios
            .get(PROJECT_APPLICATIONS_DIRECTOR_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting manager selection:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });

        axios
            .get(VENUES_ENDPOINT(), getJSONConfig())
            .then(response => {
                setVenues(response.data);
            });

        axios
            .get(STREAMS_ENDPOINT, getJSONConfig())
            .then(response => {
                // Сортируем массив так, чтобы элемент "Нераспределенные проекты" был первым
                response.data.sort((a, b) => {
                    if (a.name === UNALLOCATED_PROJECTS) {
                        return -1;
                    } else if (b.name === UNALLOCATED_PROJECTS) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                setStreams(response.data);
            });
    }, []);

    const filterProjects = () => {
        return projects.filter(project => {
            // Фильтрация по площадке
            if (venueFilter.length > 0) {
                if (venueFilter.includes("-1")) {
                    if (project.venue && !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана и в фильтре выбрана опция "Не выбрана"
                    }
                } else {
                    if (!project.venue || !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана или не содержится в списке фильтра по площадке
                    }
                }
            }

            // Фильтрация по потоку
            if (streamFilter.length > 0) {
                if (!project.stream || !streamFilter.includes(project.stream.toString())) {
                    return false;
                }
            }

            // Фильтрация по поиску
            if (enteredFilter.length > 0) {
                if (!project.name.toLowerCase().includes(enteredFilter.toLowerCase())) {
                    return false; // Если подстрока не найдена в имени проекта
                }
            }

            return true;
        });
    };

    useEffect(() => {
        const Debounce = setTimeout(() => {
            setEnteredFilter(enteredValue);
        }, 300);

        return () => clearTimeout(Debounce);
    }, [enteredValue]);

    const filteredProjects = filterProjects();

    const priorityOneCount = filteredProjects.reduce((count, project) => {
        return count + project.participants.filter(participant => participant.priority === 0).length;
    }, 0);

    return (<>
        <h3 className="mb-2">Заявки на участие</h3>
        <hr className="clearfix w-100 pb-0"/>

        <div className={'bmm-text_bold mt-3 mb-3'}>Кол-во людей, подавших заявку первым приоритетом: {priorityOneCount}</div>

        <div className="project-filters middle-filters-label">
            <div className="filters-column">
                <div className="filter">
                    <label htmlFor="venueFilter">Площадка:</label>
                    <Select id="venueFilter"
                            multiple={true}
                            hasClear
                            placeholder={'Все'}
                        // value={user && !user.is_ws_director ? ['2'] : undefined} // TODO: доставать id управляемой площадки или id площадки потока
                        // disabled={user && !user.is_ws_director}
                            onUpdate={(values) => setVenueFilter(values)}>
                        <Select.Option value={"-1"}>Не выбрана</Select.Option>
                        {venues.map((venue) => (<Select.Option key={venue.id}
                                                               value={venue.id.toString()}>{venue.name}</Select.Option>))}
                    </Select>
                </div>
            </div>

            <div className="filters-column narrow-filter-label">
                <div className="filter">
                    <label htmlFor="streamFilter">Поток:</label>
                    <Select id="streamFilter"
                            multiple={true}
                            hasClear
                            placeholder={'Все'}
                        // value={user && !user.is_ws_director && !user.is_lws_director ? ['-1'] : undefined} // TODO: доставать id управляемого потока
                        // disabled={user && !user.is_ws_director && !user.is_lws_director}
                            onUpdate={(values) => setStreamFilter(values)}>
                        {streams.map((stream) => (<Select.Option key={stream.id}
                                                                 value={stream.id.toString()}>{stream.name}</Select.Option>))}
                    </Select>
                </div>
            </div>
        </div>

        <TextInput className={"search-input mt-2 mb-2"}
                   size="l"
                   placeholder="Поиск..."
                   leftContent={<Icon data={Magnifier}/>}
                   hasClear={true}
                   value={enteredValue}
                   onChange={(e) => setEnteredValue(e.target.value)}
        />

        <div className="projects__quantity-info quantity-info">
            Кол-во проектов: {filteredProjects.length}
        </div>

        {isLoading && <LoadingSpinner/> || <>
            <div className="two-columns-container mt-3">
                {filteredProjects.length > 0 && filteredProjects.map((project, index) => (
                    <div key={index} className={"bmm-card project-card columns-item"}>
                        <div className="selection-title">
                            <Link to={`/project/${project.id}`} className="project-card__title single-line-text"
                                  target="_blank"
                                  rel="noopener noreferrer">
                                {project.name}
                            </Link>
                            {/*<h5 className={"project-card__title single-line-text"}>*/}
                            {/*    {project.name}*/}
                            {/*</h5>*/}
                        </div>
                        {project.participants.length > 0 && <div
                            className={"bmm-card bmm-card_white d-flex flex-column " + "bmm-text_bold text-reset text-decoration-none mt-2"}>
                            {project.participants.map((participant, index) => (
                                <div key={index} className={"d-flex align-items-center justify-content-between"}>
                                            <span
                                                className={index > 0 ? "mt-2 d-flex align-items-center" : " d-flex align-items-center"}>
                                                <span className={"me-2"}
                                                      title={"Приоритет проекта в заявке участника"}>
                                                    <Label theme={getThemeByPriority(participant.priority)}>
                                                        {participant.priority + 1}
                                                    </Label>
                                                </span>
                                                <Link to={`${participant.id}`}>
                                                    {participant.name}
                                                </Link>
                                            </span>
                                    {participant.grade && <span>{getGradeText(participant.grade)}</span>}
                                </div>))}
                        </div>}
                    </div>))}
            </div>
        </>}
    </>);
}

export default DirectorProjectApplicationsPage;