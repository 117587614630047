import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Select, Button, Icon} from "@gravity-ui/uikit";
import {FileArrowDown, FileArrowUp, FileCheck, FileText, FileZipper} from "@gravity-ui/icons";

import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    getJSONConfig,
    REFLECTIONS_DOWNLOAD_ENDPOINT,
    REFLECTIONS_REVIEW_ENDPOINT,
    REFLECTIONS_TEMPLATE_DETAIL_ENDPOINT,
    REFLECTIONS_TEMPLATES_ENDPOINT,
    REVIEW_PROJECTS_ENDPOINT,
    STREAMS_ENDPOINT,
    VENUES_ENDPOINT
} from '../../api/api';
import ProjectReviewCard from "../../components/projectCard/ProjectReviewCard";
import {STATUS_CHOICES} from "../../components/projectCard/utils";
import {REFLECTION_FORMATS, UNALLOCATED_PROJECTS} from "../../utils/const";
import LoadingSpinner from "../../components/LoadingSpinner";
import {HoverableIconLikeButton} from "../../components/Button/Button";
import {Form} from "react-bootstrap";
import {connect} from "react-redux";
import {openFileInNewTab} from "../../utils/utils";

function ProjectReviewList({user}) {
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [userFiles, setUserFiles] = useState([]);
    const [venues, setVenues] = useState([]);
    const [streams, setStreams] = useState([]);

    const [showReflectionUpload, setShowReflectionUpload] = useState(false);

    const [statusFilter, setStatusFilter] = useState([]);
    const [venueFilter, setVenueFilter] = useState([]);
    const [streamFilter, setStreamFilter] = useState('');
    const [curatorFilter, setCuratorFilter] = useState([]);
    const [schoolchildrenFilter, setSchoolchildrenFilter] = useState('');

    const [templates, setTemplates] = useState({
        worker_reflection: null,
        worker_feedback: null,
        manager_reflection: null,
        manager_feedback: null
    });
    const [successIcons, setSuccessIcons] = useState({
        worker_reflection: false,
        worker_feedback: false,
        manager_reflection: false,
        manager_feedback: false
    });
    const [submitting, setSubmitting] = useState(false);

    const handleFileUpload = async (e, type) => {
        setSubmitting(true);

        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('workshop', '1');
            formData.append('file', file);
            formData.append('type_file', type);

            const templateExists = !!templates[type];

            axios({
                method: templateExists ? 'put' : 'post',
                url: templateExists ? REFLECTIONS_TEMPLATE_DETAIL_ENDPOINT(templates[type].id) : REFLECTIONS_TEMPLATES_ENDPOINT,
                data: formData,
                ...getAcceptAuthConfig()
            })
                .then(response => {
                    setTemplates(prevTemplates => ({
                        ...prevTemplates,
                        [type]: response.data
                    }));
                    setSuccessIcons(prevSuccessIcons => ({
                        ...prevSuccessIcons,
                        [type]: true
                    }));
                    setTimeout(() => {
                        setSuccessIcons(prevSuccessIcons => ({
                            ...prevSuccessIcons,
                            [type]: false
                        }));
                    }, 1500);
                })
                .catch(error => {
                    console.error('Ошибка при загрузке шаблона:', error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            setSubmitting(false);
            alert('Пожалуйста, выберите файл формата Word.');
        }
    };

    const handleDownloadTemplate = (template) => {
        if (template) {
            openFileInNewTab(null, template.file, template.type_file);
        } else {
            alert('Шаблон не загружен.');
        }
    };

    useEffect(() => {
        axios
            .get(REVIEW_PROJECTS_ENDPOINT, getJSONAcceptAuthConfig())
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting projects for review:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });

        axios
            .get(VENUES_ENDPOINT(), getJSONConfig())
            .then(response => {
                setVenues(response.data);
            });

        axios
            .get(STREAMS_ENDPOINT, getJSONConfig())
            .then(response => {
                // Сортируем массив так, чтобы элемент "Нераспределенные проекты" был первым
                response.data.sort((a, b) => {
                    if (a.name === UNALLOCATED_PROJECTS) {
                        return -1;
                    } else if (b.name === UNALLOCATED_PROJECTS) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                setStreams(response.data);
            });

        axios
            .get(REFLECTIONS_REVIEW_ENDPOINT, getJSONAcceptAuthConfig())
            .then(response => {
                setUserFiles(response.data.user_files);
            });

        axios
            .get(REFLECTIONS_TEMPLATES_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                const templates = response.data;
                const fetchedTemplates = response.data.reduce((acc, template) => {
                    acc[template.type_file] = template;
                    return acc;
                }, {});
                setTemplates(fetchedTemplates);
            })
            .catch(error => {
                console.error('Ошибка при получении шаблонов:', error);
            });
    }, []);

    const reflectionQuantity = (id => userFiles.filter(file => id === file.project).length);
    const handleDownloadReflectionZIP = async (e, projectIds) => {
        const reflectionIds = userFiles.filter(file => projectIds.includes(file.project)).map(file => file.id);
        const body = JSON.stringify({files_ids: reflectionIds});
        await axios.post(REFLECTIONS_DOWNLOAD_ENDPOINT, body, {
            ...getJSONAcceptAuthConfig(),
            responseType: 'blob' // Указываем тип ответа blob для получения файла
        })
            .then(response => {
                // Создаем объект URL для полученного архива
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'reflections_and_feedbacks.zip'); // Указываем имя файла
                document.body.appendChild(link);
                link.click();

                // Очищаем объект URL
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Ошибка при скачивании архива', error);
            });
    }

    const filterProjects = () => {
        return projects.filter(project => {
            // Фильтрация по статусу
            if (statusFilter.length > 0 && !statusFilter.includes(project.status)) {
                return false;
            }

            // Фильтрация по площадке
            if (venueFilter.length > 0) {
                if (venueFilter.includes("-1")) {
                    if (project.venue && !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана и в фильтре выбрана опция "Не выбрана"
                    }
                } else {
                    if (!project.venue || !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана или не содержится в списке фильтра по площадке
                    }
                }
            }

            // Фильтрация по потоку
            if (streamFilter.length > 0) {
                if (!project.stream || !streamFilter.includes(project.stream.toString())) {
                    return false;
                }
            }

            // Фильтрация по наличию/отсутствию куратора
            if (curatorFilter.length > 0) {
                if (curatorFilter[0] === "with_curator" && (!project.managers || project.managers.length === 0)) {
                    return false;
                }
                if (curatorFilter[0] === "without_curator" && project.managers && project.managers.length > 0) {
                    return false;
                }
            }

            // Фильтрация по наличию или отсутствию школьных проектов
            if (schoolchildrenFilter.length > 0) {
                if (schoolchildrenFilter[0] === 'true' && !project.is_for_schoolchildren) {
                    return false;
                }
                if (schoolchildrenFilter[0] === 'false' && project.is_for_schoolchildren) {
                    return false;
                }
            }

            return true;
        });
    };

    const filteredProjects = filterProjects();

    return (
        <>
            <h3 className="mb-2">Поданные проекты</h3>
            <hr className="clearfix w-100 pb-0"/>

            {user && user.is_ws_director &&
                <>
                    <div className="two-columns-container">
                        <div>
                            <h5>Шаблоны для участников</h5>
                            <div className="template-section">
                                {successIcons.worker_reflection ?
                                    <Icon data={FileCheck} size={46} className={"success-icon"}/> :
                                    <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}
                                                             size={46}
                                                             onClick={() => handleDownloadTemplate(templates.worker_reflection)}
                                                             disabled={!templates.worker_reflection}
                                    />}
                                <Form.Group>
                                    <Form.Label>Шаблон рефлексии участника</Form.Label>
                                    <Form.Control type="file" size="sm"
                                                  accept={REFLECTION_FORMATS}
                                                  name="workerReflectionTemplate"
                                                  onChange={(e) => handleFileUpload(e, 'worker_reflection')}
                                                  disabled={submitting}/>
                                </Form.Group>
                            </div>
                            {/*<div className="template-section">*/}
                            {/*    {successIcons.worker_feedback ?*/}
                            {/*        <Icon data={FileCheck} size={46} className={"success-icon"}/> :*/}
                            {/*        <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}*/}
                            {/*                                 size={46}*/}
                            {/*                                 onClick={() => handleDownloadTemplate(templates.worker_feedback)}*/}
                            {/*                                 disabled={!templates.worker_feedback}*/}
                            {/*        />}*/}
                            {/*    <Form.Group>*/}
                            {/*        <Form.Label>Шаблон обратной связи участника</Form.Label>*/}
                            {/*        <Form.Control type="file" size="sm"*/}
                            {/*                      accept=".docx"*/}
                            {/*                      name="workerReflectionTemplate"*/}
                            {/*                      onChange={(e) => handleFileUpload(e, 'worker_feedback')}*/}
                            {/*                      disabled={submitting}/>*/}
                            {/*    </Form.Group>*/}
                            {/*</div>*/}
                        </div>
                        <div>
                            <h5>Шаблоны для кураторов</h5>
                            <div className="template-section">
                                {successIcons.manager_reflection ?
                                    <Icon data={FileCheck} size={46} className={"success-icon"}/> :
                                    <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}
                                                             size={46}
                                                             onClick={() => handleDownloadTemplate(templates.manager_reflection)}
                                                             disabled={!templates.manager_reflection}
                                    />}
                                <Form.Group>
                                    <Form.Label>Шаблон рефлексии куратора</Form.Label>
                                    <Form.Control type="file" size="sm"
                                                  accept={REFLECTION_FORMATS}
                                                  name="workerReflectionTemplate"
                                                  onChange={(e) => handleFileUpload(e, 'manager_reflection')}
                                                  disabled={submitting}/>
                                </Form.Group>
                            </div>
                            {/*<div className="template-section">*/}
                            {/*    {successIcons.manager_feedback ?*/}
                            {/*        <Icon data={FileCheck} size={46} className={"success-icon"}/> :*/}
                            {/*        <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}*/}
                            {/*                                 size={46}*/}
                            {/*                                 onClick={() => handleDownloadTemplate(templates.manager_feedback)}*/}
                            {/*                                 disabled={!templates.manager_feedback}*/}
                            {/*        />}*/}
                            {/*    <Form.Group>*/}
                            {/*        <Form.Label>Шаблон обратной связи куратора</Form.Label>*/}
                            {/*        <Form.Control type="file" size="sm"*/}
                            {/*                      accept=".docx"*/}
                            {/*                      name="workerReflectionTemplate"*/}
                            {/*                      onChange={(e) => handleFileUpload(e, 'manager_feedback')}*/}
                            {/*                      disabled={submitting}/>*/}
                            {/*    </Form.Group>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <hr className="clearfix w-100 pb-0"/>
                </>
            }

            <div className="project-filters wide-filters-label">
                <div className="filters-column">
                    <div className="filter">
                        <label htmlFor="statusFilter">Статус:</label>
                        <Select id="statusFilter"
                                multiple={true}
                                hasClear
                                placeholder={'Все'}
                                onUpdate={(values) => setStatusFilter(values)}
                                renderOption={(option) => (
                                    <div style={{color: option.data.color}}>{option.children}</div>)}>
                            {Object.keys(STATUS_CHOICES).map((status) => (
                                <Select.Option key={status} value={status} data={{color: STATUS_CHOICES[status].color}}>
                                    {STATUS_CHOICES[status].label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>

                    <div className="filter">
                        <label htmlFor="venueFilter">Площадка:</label>
                        <Select id="venueFilter"
                                multiple={true}
                                hasClear
                                placeholder={'Все'}
                            // value={user && !user.is_ws_director ? ['2'] : undefined} // TODO: доставать id управляемой площадки или id площадки потока
                            // disabled={user && !user.is_ws_director}
                                onUpdate={(values) => setVenueFilter(values)}>
                            <Select.Option value={"-1"}>Не выбрана</Select.Option>
                            {venues.map((venue) => (
                                <Select.Option key={venue.id}
                                               value={venue.id.toString()}>{venue.name}</Select.Option>
                            ))}
                        </Select>
                    </div>

                    <div className="filter">
                        <label htmlFor="streamFilter">Поток:</label>
                        <Select id="streamFilter"
                                multiple={true}
                                hasClear
                                placeholder={'Все'}
                            // value={user && !user.is_ws_director && !user.is_lws_director ? ['-1'] : undefined} // TODO: доставать id управляемого потока
                            // disabled={user && !user.is_ws_director && !user.is_lws_director}
                                onUpdate={(values) => setStreamFilter(values)}>
                            {streams.map((stream) => (
                                <Select.Option key={stream.id}
                                               value={stream.id.toString()}>{stream.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className="filters-column wide-filters-label narrow-filters-select">
                    <div className="filter">
                        <label htmlFor="curatorFilter">Наличие куратора:</label>
                        <Select id="curatorFilter"
                                hasClear
                                placeholder={'Все'}
                                onUpdate={(value) => setCuratorFilter(value)}>
                            <Select.Option value="with_curator">Есть куратор</Select.Option>
                            <Select.Option value="without_curator">Нет куратора</Select.Option>
                        </Select>
                    </div>

                    <div className="filter">
                        <label htmlFor="schoolchildrenFilter">Для школьников:</label>
                        <Select id="schoolchildrenFilter"
                                hasClear
                                placeholder={'Все'}
                                onUpdate={(values) => setSchoolchildrenFilter(values)}>
                            <Select.Option value="true">Да</Select.Option>
                            <Select.Option value="false">Нет</Select.Option>
                        </Select>
                    </div>
                </div>
            </div>

            <div className={"d-flex align-items-center justify-content-between"}>
                <Button view="normal" size="m"
                        onClick={(e) => handleDownloadReflectionZIP(e, filteredProjects.map(project => project.id))}>
                    <Icon data={FileZipper} size={18} className={"reflection-color"}/>
                    Скачать рефлексии показанных проектов
                    (файлов: {userFiles.filter(file => filteredProjects.map(project => project.id).includes(file.project)).length})
                </Button>
                <div className="projects__quantity-info quantity-info mt-0">
                    Кол-во проектов: {filteredProjects.length}
                </div>
            </div>
            {(templates.worker_reflection || templates.manager_reflection) &&
                <Button view="normal" size="m" className={"mt-2"}
                        onClick={() => setShowReflectionUpload((prevShow) => !prevShow)}>
                    <Icon data={FileArrowUp} size={18} className={"reflection-color"}/>
                    {showReflectionUpload && "Скрыть кнопки загрузки рефлексий за других" || "Показать кнопки загрузки рефлексий за других"}
                </Button>
            }

            {isLoading &&
                <LoadingSpinner/> ||
                (filteredProjects.map((project) => {
                    // Функция для проверки наличия объекта с полем project = project.id
                    const hasUserFile = userFiles.some(file => file.project === project.id);

                    return (
                        <ProjectReviewCard key={"PRC" + project.id} project={project}
                                           hasUserFile={hasUserFile} onClick={handleDownloadReflectionZIP}
                                           reflectionQuantity={reflectionQuantity}
                                           userFiles={userFiles} setUserFiles={setUserFiles}
                                           workerReflectionId={templates.worker_reflection?.id}
                                           managerReflectionId={templates.manager_reflection?.id}
                                           showReflectionUpload={showReflectionUpload}/>
                    );
                }))
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps)(ProjectReviewList);