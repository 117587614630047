import React, {useEffect, useState} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";
import {getJSONAcceptAuthConfig, WORKSHOPS_INFO_ENDPOINT} from "../api/api";
import LoadingSpinner from "../components/LoadingSpinner";

function CanUpdateTravel({children, user}) {
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const location = useLocation();

    const [activeWorkshop, setActiveWorkshop] = useState(null);

    useEffect(() => {
        axios
                    .get(WORKSHOPS_INFO_ENDPOINT, {
                        params: {is_active: "True"},
                        headers: getJSONAcceptAuthConfig().headers,
                    })
                    .then(response => {
                        setActiveWorkshop((response.data[0]))
                    })
                    .catch(error => {
                        console.error('Error getting active workshop data:', error);
                    })
    }, []);

    const is_bad_user = () => {
        return (
            !user?.last_name ||
            !user?.first_name ||
            !user?.middle_name ||
            !user?.birth_date
        );
    };

    if (user === null || activeWorkshop === null) {
        return <LoadingSpinner/>
    }

    if (!activeWorkshop?.travellers_can_apply || is_bad_user()) {
        // Если нельзя подавать заявку или пользователь не валиден, перенаправляем
        return (
            <Navigate to="/account/travel" state={{ from: location }} replace />
        );
    }

    return children; // Если всё в порядке, рендерим дочерние элементы
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(CanUpdateTravel)