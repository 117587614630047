import React, {useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import {Button, Icon} from "@gravity-ui/uikit";
import {Bold, Eye, Link as LinkIcon, LinkSlash, ListOl, ListUl, LogoMarkdown} from "@gravity-ui/icons";
import BMMMarkdown from "./BMMMarkdown";

const MenuBar = ({isPreview, setIsPreview}) => {

    return (
        <div className="text-editor-toolbar text-editor-toolbar-right">
            <div className="text-editor-toolbar-group">
                <Button view="flat" size="xs" title="Markdown"
                    // onClick={() => editor.chain().focus().toggleBold().run()}
                    // disabled={!editor.can().chain().focus().toggleBold().run()}
                    // className={editor.isActive('bold') ? 'is-active' : ''}
                >
                    <Icon data={LogoMarkdown} size={18}/>
                </Button>
                <Button view="flat" size="xs" title="Предпросмотр"
                        onClick={() => setIsPreview(!isPreview)}
                    // disabled={!editor.can().chain().focus().toggleBold().run()}
                        className={isPreview ? 'is-active' : ''}
                >
                    <Icon data={Eye} size={18}/>
                </Button>
            </div>

            {/*<div className="text-editor-toolbar-group">*/}
            {/*    <Button view="flat" size="xs" title="Нумерованный список"*/}
            {/*            onClick={() => editor.chain().focus().toggleOrderedList().run()}*/}
            {/*            className={editor.isActive('orderedList') ? 'is-active' : ''}>*/}
            {/*        <Icon data={ListOl} size={18}/>*/}
            {/*    </Button>*/}
            {/*    <Button view="flat" size="xs" title="Маркированный список"*/}
            {/*            onClick={() => editor.chain().focus().toggleBulletList().run()}*/}
            {/*            className={editor.isActive('bulletList') ? 'is-active' : ''}>*/}
            {/*        <Icon data={ListUl} size={18}/>*/}
            {/*    </Button>*/}
            {/*</div>*/}

            {/*<div className="text-editor-toolbar-group">*/}
            {/*    <Button view="flat" size="xs" title="Ссылка"*/}
            {/*            onClick={setLink}*/}
            {/*            className={editor.isActive('link') ? 'is-active' : ''}>*/}
            {/*        <Icon data={LinkIcon} size={18}/>*/}
            {/*    </Button>*/}
            {/*    <Button view="flat" size="xs" title="Удалить ссылку"*/}
            {/*            onClick={() => editor.chain().focus().unsetLink().run()}*/}
            {/*            disabled={!editor.isActive('link')}>*/}
            {/*        <Icon data={LinkSlash} size={18}/>*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    )
}

function WorkshopAboutForm({about, onChange, submitting, onSubmit}) {
    const [isPreview, setIsPreview] = useState(false);

    const importAll = (r) => r.keys().map(r);
    const images = importAll(require.context('../../../../public/static/aboutImg', false, /\.(png|jpg|svg|webp)$/));

    return (
        <>
            {/*<div className={`text-editor`}>*/}
            {/*    <MenuBar isPreview={isPreview} setIsPreview={setIsPreview}/>*/}
            {/*    <Form.Control as="textarea" rows={20}*/}
            {/*                  name="md_text"*/}
            {/*                  value={about || ""}*/}
            {/*                  placeholder="Описание мастерской"*/}
            {/*                  onChange={onChange}*/}
            {/*                  style={{*/}
            {/*                      border: 'none',       // Убираем границы*/}
            {/*                      outline: 'none',      // Убираем фокусную рамку*/}
            {/*                      boxShadow: 'none',    // Убираем тени*/}
            {/*                      resize: 'none',       // Опционально: убираем возможность изменения размера*/}
            {/*                  }}*/}
            {/*    />*/}
            {/*</div>*/}

            <div className={"d-flex justify-content-end"}>
                <Button view="flat" size="xs" title="Markdown"
                    // onClick={() => editor.chain().focus().toggleBold().run()}
                    // disabled={!editor.can().chain().focus().toggleBold().run()}
                    // className={editor.isActive('bold') ? 'is-active' : ''}
                >
                    <Icon data={LogoMarkdown} size={18}/>
                </Button>
            </div>

            <Form.Control as="textarea" rows={15}
                          name="md_text"
                          value={about || ""}
                          placeholder="Описание мастерской"
                          onChange={onChange}
            />

            <Button view="action" type="button" className="mt-3 mb-4 w-100" size="l"
                    disabled={submitting} onClick={onSubmit}>
                {submitting ? "Сохранение..." : "Сохранить"}
            </Button>

            <BMMMarkdown markdown_2={about || ""} images={images}/>
        </>
    );
}

export default WorkshopAboutForm;