import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";

import {File} from "@gravity-ui/icons";

import {
    getAcceptAuthConfig, MANAGER_PROJECTS_ENDPOINT,
    PROJECT_DISTRIBUTION_MANAGER_ENDPOINT
} from "../../../api/api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {getGradeText} from "../../../utils/utils";
import {Accordion} from "react-bootstrap";
import ProjectManagerCard from "../../../components/projectCard/ProjectManagerCard";

const SelectionProjectList = ({projects}) => {
    return (
        <>
            {projects.length > 0 && projects.map((project, index) => (
                <div key={index} className={"bmm-card project-card"}>
                    <div className="selection-title">
                        <h5 className={"project-card__title single-line-text"}>
                            {project.name}
                        </h5>
                        {project.test_file &&
                            <a href={project.test_file} target="_blank"><File/>Тестовое задание</a>}
                    </div>
                    {project.participants.length > 0 && project.participants.map((participant, index) => (
                        <Link to={`${participant.id}`}
                              key={index}
                              className={"bmm-card bmm-card_white d-flex align-items-center justify-content-between " +
                                  `bmm-text_bold text-reset text-decoration-none ${participant.participation_confirmation === 'confirmed' && "background-confirmed" || participant.participation_confirmation === 'declined' && "background-declined"}`}>
                            <span>{participant.name}</span>
                            {participant.grade &&
                                <span>{getGradeText(participant.grade)}</span>}
                        </Link>))}
                </div>
            ))}
        </>
    )
}

function ProjectManagerSelection() {
    const [isLoading, setIsLoading] = useState(true);

    const [projects, setProjects] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [notActiveProjects, setNotActiveProjects] = useState([]);

    useEffect(() => {
        // TODO: определить, когда делать setIsLoading(false)
        axios
            .get(PROJECT_DISTRIBUTION_MANAGER_ENDPOINT, {
                params: {is_active: "True"},
                headers: getAcceptAuthConfig().headers,
            })
            .then(response => {
                setActiveProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting manager selection:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        axios
            .get(PROJECT_DISTRIBUTION_MANAGER_ENDPOINT, {
                params: {is_active: "False"},
                headers: getAcceptAuthConfig().headers,
            })
            .then(response => {
                setNotActiveProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting manager selection:', error);
            })
    }, []);

    return (
        <>
            <h3 className="mb-2">Заявки на участие</h3>
            <hr className="clearfix w-100 pb-0"/>

            {isLoading &&
                <LoadingSpinner/> ||
                <>
                    <SelectionProjectList projects={activeProjects}/>

                    {notActiveProjects.length > 0 &&
                        <Accordion className="about-accordion" alwaysOpen>
                            <Accordion.Item eventKey={`archive`}>
                                <Accordion.Header>
                                    <h4 className="m-0">Архив</h4>
                                </Accordion.Header>
                                <Accordion.Body className="local-workshop-list">
                                    <SelectionProjectList projects={notActiveProjects}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                </>
            }

            {/*{projects.reverse().map((project) => (*/}
            {/*    <ProjectUserCard key={project.id} project={project} onDelete={onDelete}></ProjectUserCard>))}*/}
        </>
    );
}

export default ProjectManagerSelection;