import React from 'react';
import {Link} from "react-router-dom";

import {Button, Icon} from "@gravity-ui/uikit"
import {ArrowUpRightFromSquare} from "@gravity-ui/icons";

import {ProjectDate, ProjectForSchool, ProjectKeywords, ProjectVenue, ProjectWorkshop} from "./utils"
import './ProjectCard.css'
import {REFLECTIONS_WORKER_DETAIL_ENDPOINT, REFLECTIONS_WORKER_ENDPOINT} from "../../api/api";
import ReflectionUpload from "../ReflectionUpload";

function ProjectWorkerCard({
                               project,
                               templates = null,
                               userFiles = null,
                               setUserFiles = null,
                               submitting = null,
                               setSubmitting = null,
                               is_active = true
                           }) {
    return (
        <div className="bmm-card project-card project-card-draft">
            <div className="project-card__pretitle">
                <div className="me-1">
                    {!is_active &&
                        <ProjectWorkshop workshop={project.workshop}/>
                    }
                    <ProjectVenue venue={project.venue}/>
                </div>
                <div>
                    <ProjectForSchool is_for_schoolchildren={project.is_for_schoolchildren}/>
                </div>
            </div>

            <span className="project-card__title">{project.name}</span>

            <div className="project__keywords project-card__keywords">
                <ProjectKeywords keywords={project.keywords}/>
            </div>

            <div className="project-card__text" dangerouslySetInnerHTML={{__html: project.description}}/>

            {templates && is_active &&
                <ReflectionUpload templates={templates} userFiles={userFiles} setUserFiles={setUserFiles}
                                  submitting={submitting} setSubmitting={setSubmitting}
                                  projectId={project.id}
                                  detailEndpoint={REFLECTIONS_WORKER_DETAIL_ENDPOINT}
                                  endpoint={REFLECTIONS_WORKER_ENDPOINT}/>
            }

            <div className="project-card__subtitle">
                <div className="project-card__subtitle-date">
                    <ProjectDate date={new Date(project.created_on)}/>
                </div>
                <div className="group-btn">
                    <Link to={`/project/${project.id}`}>
                        {/*FIXME: чёт в случае участника проекта "Предпросмотр" не оч подходит для этой кнопки*/}
                        <Button view={"normal"} size={"m"} title={"Предпросмотр"}>
                            <Icon data={ArrowUpRightFromSquare} size={18}/>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ProjectWorkerCard;