import React, {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import axios from "axios";

import LoadingSpinner from "../components/LoadingSpinner";
import {getJSONAcceptAuthConfig, USER_APPLICATIONS_ENDPOINT, WORKSHOPS_INFO_ENDPOINT} from "../api/api";

function CanCreateApplication({children}) {
    const location = useLocation();
    const [applicationData, setApplicationData] = useState(null);

    const [activeWorkshop, setActiveWorkshop] = useState(null);

    useEffect(() => {
        Promise.all([
            axios
                    .get(WORKSHOPS_INFO_ENDPOINT, {
                        params: {is_active: "True"},
                        headers: getJSONAcceptAuthConfig().headers,
                    })
                    .then(response => {
                        setActiveWorkshop((response.data[0]))
                    })
                    .catch(error => {
                        console.error('Error getting active workshop data:', error);
                    }),
            axios
                .get(USER_APPLICATIONS_ENDPOINT, {
                    params: {is_active: "True"},
                    headers: getJSONAcceptAuthConfig().headers,
                })
                .then(response => {
                    setApplicationData(response.data[0]?.cv);
                })
                .catch(error => {
                    console.error('Error getting application data:', error);
                })
        ]);
    }, []);

    if (applicationData === null || activeWorkshop === null) {
        return <LoadingSpinner/>
    }

    return (applicationData?.participant || !activeWorkshop?.workers_can_apply)
        ? <Navigate to='/account/application' state={{from: location}} replace={true}/>
        : children;
}

export default CanCreateApplication;