import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import axios from "axios";

import {Button, Icon} from "@gravity-ui/uikit";

import Timeline from "../../components/timeline/Timeline";
import {ACTIVE_STAGE_ENDPOINT, WORKSHOPS_INFO_ENDPOINT, getAcceptAuthConfig, getJSONConfig} from "../../api/api";
import {EditButtonIcon} from "../../components/Button/Button";
import WorkshopStagesForm from "../accountPage/Director/WorkshopStagesForm";
import {EditSlash} from "../../components/Icons/Icons";

const StagesPage = ({user}) => {
    const [stages, setStages] = React.useState([]);
    const [editStages, setEditStages] = React.useState([]);
    const [workshopId, setWorkshopId] = React.useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        axios
            .get(ACTIVE_STAGE_ENDPOINT, getJSONConfig())
            .then(response => {
                setStages(response.data);
                setEditStages(response.data);
            })
            .catch(error => {
                console.error('Не удалось получить этапы:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });

        //FIXME: В Arc (мб и других браузеах) при обновлении страницы пользователь равен null, поэтому if пока закомментирован

        // if (user && user.is_ws_director) {
        axios
            .get(WORKSHOPS_INFO_ENDPOINT, {
                params: {is_active: "True"}, // Query parameter for filtering
                headers: getJSONConfig().headers, // Authorization headers
            })
            .then(response => {
                if (response.data.length > 0) {
                    setWorkshopId(response.data[0].id); // Установка id первой мастерской
                }
            })
        // }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .post(ACTIVE_STAGE_ENDPOINT, editStages, getAcceptAuthConfig())
            .then(response => {
                setStages(response.data);
                setEditStages(response.data);
                setIsEditing(false);
            })
            .catch(error => {
                console.error('Не удалось сохранить этапы:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const onCanselClick = () => {
        setEditStages(stages);
        setIsEditing(false);
    }

    function isCompletedDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return (new Date(year, month - 1, day) <= new Date());
    }

    function isCompletedPrevDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return (new Date(year, month - 1, day) < new Date());
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    return (
        <>
            <div className={"d-flex justify-content-between align-items-center mb-5"}>
                <div className="page-title">Этапы Мастерской</div>
                {/*TODO: добавить loading spinner*/}
                {
                    // иконка редактирования этапов мастерской будет показываться только директору мастерской при наличии активной мастерской
                    user && user.is_ws_director && workshopId != null && (
                        isEditing ? (
                            <Button view={"normal"} size={"m"} onClick={() => setIsEditing(false)}
                                    title={"Отменить редактирование"}>
                                <Icon data={EditSlash} size={18}/>
                            </Button>
                        ) : (
                            <EditButtonIcon onClick={handleEditClick}/>
                        )
                    )
                }
            </div>
            {/*FIXME: захардкоженные сроки мастерской (думаю вообще убрать эту инфу отсюда)*/}
            {/*<div className="bmm-period">8-14 июля + межмодуль + 18-23 июля 2024 года</div>*/}

            {isEditing ? (
                <>
                    <WorkshopStagesForm stages={editStages} setStages={setEditStages} workshopId={workshopId}/>

                    <div className="line-btn-group flex-column-reverse flex-sm-row mt-3">
                        <Button view="normal" size="l" onClick={onCanselClick} width="max">Отменить</Button>
                        <Button view="action" type="button" onClick={onSubmit} disabled={submitting}
                                size="l" width="max">
                            {submitting ? "Сохранение..." : "Сохранить"}
                        </Button>
                    </div>
                </>
            ) : (
                <Timeline position="left">
                    {stages.map((stage, index) => {
                        const startDate = formatDate(stage.date);
                        const finishDate = stage.finish_date ? ` – ${formatDate(stage.finish_date)}` : '';
                        const date = `${startDate}${finishDate}`;

                        return (
                            <Timeline.Item key={index}
                                           completed={stage.date && isCompletedDate(stage.date)
                                               || !stage.date && stages[index - 1]?.finish_date && isCompletedPrevDate(stages[index - 1]?.finish_date)
                                               || !stage.date && stages[index - 1]?.date && isCompletedPrevDate(stages[index - 1]?.date)}>
                                <Timeline.Date>{date}</Timeline.Date>
                                <Timeline.Description>
                                    <Timeline.Title>{stage.title}</Timeline.Title>
                                    {stage.description && <Timeline.HTMLText>{stage.description}</Timeline.HTMLText>}
                                </Timeline.Description>
                            </Timeline.Item>
                        );
                    })}
                </Timeline>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(StagesPage);