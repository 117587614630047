import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";

import {Button, Icon, Label, Text} from "@gravity-ui/uikit";
import {Check, FileCheck, FileExclamation, Xmark} from "@gravity-ui/icons";
import {Accordion} from "react-bootstrap";

import {
    CONFIRMATION_DETAIL_ENDPOINT,
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    USER_APPLICATIONS_DETAIL_ENDPOINT,
    USER_APPLICATIONS_ENDPOINT, WORKSHOPS_INFO_ENDPOINT
} from "../../../api/api";
import {DeleteButtonIcon, EditButtonIcon_Link} from "../../../components/Button/Button";
import {DeleteModal} from "../../../components/Modal/Modals";
import LoadingSpinner from "../../../components/LoadingSpinner";

function ParticipantPage() {
    const [isLoading, setIsLoading] = useState(true);

    const [open, setOpen] = React.useState(false);

    const [activeApplication, setActiveApplication] = useState(null);
    const [notActiveApplications, setNotActiveApplications] = useState([]);

    const [activeWorkshop, setActiveWorkshop] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        // TODO: определить, когда делать setIsLoading(false)
        Promise.all([
            axios
            .get(USER_APPLICATIONS_ENDPOINT, {
                params: {is_active: "True"},
                headers: getJSONAcceptAuthConfig().headers,
            })
            .then(response => {
                // NOTE: ожидается, что заявка на активную мастерскую будет одна, она достаётся по [0]
                setActiveApplication(response.data[0]);
            })
            .catch(error => {
                console.error('Error getting application data:', error);
            }),
            axios
                .get(WORKSHOPS_INFO_ENDPOINT, {
                    params: {is_active: "True"},
                    headers: getJSONAcceptAuthConfig().headers,
                })
                .then(response => {
                    setActiveWorkshop((response.data[0]))
                })
                .catch(error => {
                    console.error('Error getting active workshop data:', error);
                }),
        ])
            .finally(() => {
                setIsLoading(false);
            });



        axios
            .get(USER_APPLICATIONS_ENDPOINT, {
                params: {is_active: "False"},
                headers: getJSONAcceptAuthConfig().headers,
            })
            .then(response => {
                setNotActiveApplications(response.data);
            })
            .catch(error => {
                console.error('Error getting application data:', error);
            })
    }, []);

    const onDelete = async (id) => {
        try {
            await axios.delete(USER_APPLICATIONS_DETAIL_ENDPOINT(id), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении заявки:', error);
        }
    };

    const onConfirm = async (conf) => {
        try {
            const body = JSON.stringify({participation_confirmation: conf});
            await axios.put(CONFIRMATION_DETAIL_ENDPOINT(activeApplication?.cv.id), body, getJSONAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при подтверждении:', error);
        }
    };

    const currentDate = new Date();
    const maxDate = new Date('2024-06-07T23:59:59.999+07:00');
    // TODO: заменить на дедлайны

    if (isLoading) {
        return (<>
            <h3 className="mb-2">Заявка на участие </h3>
            <hr className="clearfix w-100 pb-0"/>
            <LoadingSpinner/>
        </>)
    }

    const getThemeByStatus = (status) => {
        switch (status) {
            case 'approved':
                return 'success';
            case 'prior_approved':
                return 'warning';
            default:
                return 'danger';
        }
    };

    const getTitleByStatus = (status) => {
        switch (status) {
            case 'approved':
                return 'Вы зачислены в этот проект';
            case 'prior_approved':
                return 'Вы зачислены в проект с приоритетом выше';
            case 'stopped_project':
                return 'Проект не запустился';
            default:
                return 'Вы не прошли в этот проект';
        }
    };

    const ApplicationCard = ({cv, projects, workshop, is_active = true}) => {
        return (
            <div className={"bmm-card project-card"}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={"d-flex align-items-center"}>
                        {is_active && <Label theme="success" className={"me-2"}>Заявка подана</Label>}
                        <h5 className={"project-card__title mt-0 mb-0"}>
                            {workshop.name} {workshop.year}
                        </h5>
                    </div>
                    {is_active && activeWorkshop?.workers_can_apply &&
                        <div className="group-btn">
                            <DeleteButtonIcon onClick={() => setOpen(true)}/>
                            <EditButtonIcon_Link to={`${cv?.id}`}/>
                        </div>
                    }
                    {activeWorkshop?.workers_can_confirm && cv?.participation_confirmation &&
                        cv?.participation_confirmation !== "not_needed" && is_active &&
                        <div className="group-btn">
                            <Button view={"outlined-success"} size={"s"} onClick={() => onConfirm('confirmed')}
                                    disabled={cv?.participation_confirmation === 'confirmed'}>
                                {cv?.participation_confirmation === 'confirmed' ?
                                    "Вы согласились" : "Буду участвовать"}
                            </Button>
                            <Button view={"outlined-danger"} size={"s"} onClick={() => onConfirm('declined')}
                                    disabled={cv?.participation_confirmation === 'declined'}>
                                {cv?.participation_confirmation === 'declined' ?
                                    "Вы отказались" : "Не буду участвовать"}
                            </Button>
                        </div>}
                </div>
                {projects.length > 0 && projects.map((project, index) => (
                    <div key={index}
                         className={"bmm-card bmm-card_white d-flex align-items-center justify-content-between"}>
                        <div className={"bmm-text_bold d-flex align-items-center"}>
                            <div className={"me-2"} title={project.status ? getTitleByStatus(project.status) : ""}>
                                <Label icon={project.status ?
                                    <Icon size={16} data={project.status === 'approved' ? Check : Xmark}/> :
                                    false}
                                       theme={project.status ? getThemeByStatus(project.status) : "normal"}>
                                    Приоритет {index + 1}
                                </Label>
                            </div>
                            {project.name}
                        </div>
                        {project.test_file && (
                            <Text title={project.file
                                ? "Файл с решением тестового задания загружен"
                                : "Не загружен файл с решением тестового задания"}
                                  color={project.file ? "positive" : "danger"}>
                                <Icon data={project.file ? FileCheck : FileExclamation} size={18}/>
                            </Text>
                        )}
                    </div>
                ))}
                {is_active && <DeleteModal type="заявку на участие в" name={`${cv.workshop}`}
                             onDelete={() => onDelete(cv?.id)}
                             open={open} setOpen={setOpen}
                />}
            </div>
        )
    }

    return (
        <>
            <h3 className="mb-2">Заявка на участие</h3>
            <hr className="clearfix w-100 pb-0"/>
            {activeWorkshop?.workers_can_apply && !activeApplication?.cv.participant  &&
                <div className="text-center mb-3">
                    <Link to={"create_application"}>
                        <Button view={"action"} size={"xl"}>
                            Подать заявку на участие
                        </Button>
                    </Link>
                </div> ||
                activeApplication?.cv.participant &&
                <ApplicationCard cv={activeApplication.cv}
                                 workshop={activeApplication.workshop}
                                 projects={activeApplication.projects_user}
                />
            }

            {notActiveApplications.length > 0 &&
                <Accordion className="about-accordion" alwaysOpen>
                    <Accordion.Item eventKey={`archive`}>
                        <Accordion.Header>
                            <h4 className="m-0">Архив</h4>
                        </Accordion.Header>
                        <Accordion.Body className="local-workshop-list">
                            {notActiveApplications.length > 0 && notActiveApplications.map((application, index) => (
                                <ApplicationCard key={index}
                                                 cv={application.cv}
                                                 workshop={application.workshop}
                                                 projects={application.projects_user}
                                                 is_active={false}
                                />
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }
        </>
    );
}

export default ParticipantPage;