import React, {useState} from 'react';
import {Form} from "react-bootstrap";

import {closestCenter, DndContext, PointerSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

import Timeline from "../../../components/timeline/Timeline";
import TipTap from "../../../components/Editor/TipTap";

const SortableStage = ({
                           stage,
                           id,
                           onChangeStage,
                           onDelete
                       }) => {
    const {
        attributes, listeners, setNodeRef, transform, transition,
    } = useSortable({id});
    const style = {
        transition, transform: CSS.Translate.toString(transform),
    };

    const handleFieldChange = (name, value) => {
        const updatedValue = value === '' ? null : value;
        onChangeStage(id, {...stage, [name]: updatedValue});
    };

    return (
        <Timeline.DraggableItem ref={setNodeRef} style={style} attributes={attributes} listeners={listeners}
                                onDelete={() => onDelete(id)}>
            <Timeline.Date>
                <Form.Control type="date" size="sm" name="date"
                              placeholder="Дата начала"
                              value={stage.date || ''}
                              onChange={(e) => handleFieldChange(e.target.name, e.target.value)}/>
                <Form.Control type="date" size="sm" name="finish_date"
                              placeholder="Дата окончания"
                              value={stage.finish_date || ''}
                              onChange={(e) => handleFieldChange(e.target.name, e.target.value)}/>
            </Timeline.Date>
            <Timeline.Description className={"w-100"}>
                <Timeline.Title>
                    <Form.Control type="text" size={"sm"}
                                  name="title"
                                  value={stage.title || ""}
                                  placeholder="Название этапа"
                                  onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                    />
                </Timeline.Title>
                <Timeline.Text hasForm={true}>
                    <TipTap size={"sm"}
                            description={stage.description}
                            setDescription={(description) => handleFieldChange('description', description)}
                    />
                </Timeline.Text>
            </Timeline.Description>
        </Timeline.DraggableItem>
    );
};

function WorkshopStagesForm({stages, setStages, workshopId}) {

    const [tempIdCounter, setTempIdCounter] = useState(-1);

    const onChangeStage = (id, updatedStage) => {
        setStages((prevStages) =>
            prevStages.map((stage) => (stage.id === id ? updatedStage : stage))
        );
    };

    const onAddStage = () => {
        const newStage = {
            id: tempIdCounter,
            workshop: workshopId,
            date: null,
            finish_date: null,
            title: null,
            description: null,
            orderID: stages.length + 1,
        };
        setStages([...stages, newStage]);
        setTempIdCounter(tempIdCounter - 1);
    }

    const onDeleteStage = (id) => {
        setStages((prevStages) => prevStages.filter((stage) => stage.id !== id));
    }

    const onDragEnd = (e) => {
        const {active, over} = e;
        if (active.id === over.id) {
            return;
        }
        setStages((prevStages) => {
            const oldIndex = prevStages.findIndex((stage) => stage.id === active.id);
            const newIndex = prevStages.findIndex((stage) => stage.id === over.id);
            const reorderedStages = arrayMove(prevStages, oldIndex, newIndex);

            // Update orderID for each stage based on its new position
            return reorderedStages.map((stage, index) => ({
                ...stage,
                orderID: index + 1, // Setting orderID to be 1-based index
            }));
        });
    };

    const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

    return (
        <Timeline position="left">
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
                <SortableContext items={stages} strategy={verticalListSortingStrategy}>
                    {stages.map((stage, index) => (
                        <SortableStage key={stage.id}
                                       id={stage.id}
                                       stage={stage}
                                       onChangeStage={onChangeStage}
                                       onDelete={onDeleteStage}/>))}
                </SortableContext>
            </DndContext>
            <Timeline.AddItem onClick={onAddStage}>
                <Timeline.Date></Timeline.Date>
                <Timeline.Description>
                    <Timeline.Title></Timeline.Title>
                    <Timeline.Text></Timeline.Text>
                </Timeline.Description>
            </Timeline.AddItem>
        </Timeline>
    );
}

export default WorkshopStagesForm;