import React, {useEffect, useState} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import axios from "axios";
import {getJSONAcceptAuthConfig, WORKSHOPS_INFO_ENDPOINT} from "../api/api";
import LoadingSpinner from "../components/LoadingSpinner";

function CanUpdateApplication({children}) {
    const location = useLocation();
    const [activeWorkshop, setActiveWorkshop] = useState(null);


    useEffect(() => {
        axios
            .get(WORKSHOPS_INFO_ENDPOINT, {
                params: {is_active: "True"},
                headers: getJSONAcceptAuthConfig().headers,
            })
            .then(response => {
                setActiveWorkshop((response.data[0]))
            })
            .catch(error => {
                console.error('Error getting active workshop data:', error);
            })
    }, []);

    if (activeWorkshop === null) {
        return <LoadingSpinner/>
    }

    return !activeWorkshop?.workers_can_apply
        ? <Navigate to='/account/application' state={{from: location}} replace={true}/>
        : children;
}

export default CanUpdateApplication;