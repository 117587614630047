import React, {useCallback, useEffect} from 'react'

import {EditorContent, useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import CharacterCount from '@tiptap/extension-character-count'

import {Button, Icon} from '@gravity-ui/uikit';
import {Bold, ListOl, ListUl, Link as LinkIcon, LinkSlash} from "@gravity-ui/icons";

import './TipTap.css'

const limit = 700

const extensions = [
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
    Link.configure({
        openOnClick: false,
        autolink: true,
    }),
    CharacterCount.configure({
        limit,
    }),
]

const MenuBar = ({editor}) => {
    // const {editor} = useCurrentEditor();

    const setLink = useCallback(() => {
        const previousUrl = editor.getAttributes('link').href;
        const url = window.prompt('Вставьте ссылку', previousUrl);

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
            return
        }

        // update link
        editor.chain().focus().extendMarkRange('link').setLink({href: url, target: '_blank'}).run();
    }, [editor]);

    if (!editor) {
        return null
    }

    return (
        <div className="text-editor-toolbar">
            <div className="text-editor-toolbar-group">
                <Button view="flat" size="xs" title="Жирный"
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        disabled={!editor.can().chain().focus().toggleBold().run()}
                        className={editor.isActive('bold') ? 'is-active' : ''}>
                    <Icon data={Bold} size={18}/>
                </Button>
            </div>

            <div className="text-editor-toolbar-group">
                <Button view="flat" size="xs" title="Нумерованный список"
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        className={editor.isActive('orderedList') ? 'is-active' : ''}>
                    <Icon data={ListOl} size={18}/>
                </Button>
                <Button view="flat" size="xs" title="Маркированный список"
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        className={editor.isActive('bulletList') ? 'is-active' : ''}>
                    <Icon data={ListUl} size={18}/>
                </Button>
            </div>

            <div className="text-editor-toolbar-group">
                <Button view="flat" size="xs" title="Ссылка"
                        onClick={setLink}
                        className={editor.isActive('link') ? 'is-active' : ''}>
                    <Icon data={LinkIcon} size={18}/>
                </Button>
                <Button view="flat" size="xs" title="Удалить ссылку"
                        onClick={() => editor.chain().focus().unsetLink().run()}
                        disabled={!editor.isActive('link')}>
                    <Icon data={LinkSlash} size={18}/>
                </Button>
            </div>
        </div>
    )
}

const TipTap = ({description, setDescription, characterLimit, size = 'md'}) => {
    // const onUpdate = ({editor}) => {
    //     setProjectData((prevData) => ({
    //         ...prevData,
    //         'description': editor ? editor.getHTML() : '',
    //     }));
    // };

    // const content = projectData.description || '';  // Ensure content is not null

    const editorExtensions = [
        StarterKit.configure({
            bulletList: {
                keepMarks: true,
                keepAttributes: false,
            },
            orderedList: {
                keepMarks: true,
                keepAttributes: false,
            },
        }),
        Link.configure({
            openOnClick: false,
            autolink: true,
        }),
        ...(characterLimit ? [CharacterCount.configure({limit: characterLimit})] : []),
    ];

    // const editor = useEditor({
    //     extensions,
    //     onUpdate({editor}) {
    //         document.querySelector('[name="description_text_editor"]').setCustomValidity('');
    //         setProjectData((prevData) => ({
    //             ...prevData,
    //             'description': editor ? editor.getHTML() : '',
    //             'description_text_editor': editor ? editor.getText() : '',
    //         }));
    //     },
    // })

    const editor = useEditor({
        extensions: editorExtensions,
        content: description || '',
        onUpdate({editor}) {
            setDescription(editor.getHTML());
        },
    });

    // useEffect(() => {
    //     if (editor?.isEmpty) {
    //         editor.commands.setContent(projectData.description || '');
    //     }
    // }, [editor, projectData.description]);

    useEffect(() => {
        if (editor && description !== editor.getHTML()) {
            editor.commands.setContent(description || '');
        }
    }, [editor, description]);

    // useEffect(() => {
    // 	if (!editor) return;
    // 	const {from, to} = editor.state.selection;
    // 	editor.commands.setContent(projectData.description,
    // 		false, {
    // 			preserveWhitespace: "full"
    // 		});
    // 	editor.commands.setTextSelection({from, to});
    // }, [editor, projectData.description]);

    const sizeClass = size ? `tiptap-${size}` : '';

    return (
        <div className={`text-editor ${sizeClass}`}>
            <MenuBar editor={editor}/>
            <EditorContent editor={editor}/>
            {/*<EditorProvider slotBefore={<MenuBar/>} extensions={extensions}*/}
            {/*                onUpdate={({editor}) => onUpdate({editor})}*/}
            {/*                content={content}>{' '}</EditorProvider>*/}
        </div>
    )
}

export default TipTap;