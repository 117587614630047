import React, {useEffect, useRef, useState} from 'react';
import InputMask from 'react-input-mask';
import axios from "axios";

import {Form, Row, Col} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {closestCenter, DndContext, PointerSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {Button, Icon} from "@gravity-ui/uikit";
import {File, Grip, TrashBin, Xmark} from "@gravity-ui/icons";

import {getJSONAcceptAuthConfig, PROJECT_SEARCH_ENDPOINT} from "../../../api/api";
import {LinkLikeButton_File} from "../../../components/Button/Button";
import Select from "react-select";
import {PhoneInput} from "react-international-phone";
import {connect} from "react-redux";

const calculateAge = (birthDate) => {
    const diffMs = Date.now() - new Date(birthDate);
    const ageDt = new Date(diffMs);
    return Math.abs(ageDt.getUTCFullYear() - 1970);
}

function TravelForm({
                        travelData,
                        setTravelData,
                        accommodationNeeded,
                        setAccommodationNeeded,
                        localWorkshops,
                        errors,
                        setErrors,
                        onSubmit,
                        submitting,
                        type,
                        user
                    }) {


    let submitButtonName, submittingButtonName;
    let isCreate = false, isUpdate = false;
    if (type === "create") {
        isCreate = true;
        submitButtonName = "Подать заявку";
        submittingButtonName = "Отправка...";
    } else if (type === "update") {
        isUpdate = true;
        submitButtonName = "Редактировать заявку";
        submittingButtonName = "Сохранение...";
    }

    const userAge = calculateAge(user?.birth_date);

    const [firstTimeInCity, setFirstTimeInCity] = useState({});

    const genderOptions = [
        {label: 'Мужской', value: 'M'},
        {label: 'Женский', value: 'F'}
    ];

    const transportOptions = [
        {label: 'Автомобиль', value: 'C'},
        {label: 'Поезд', value: 'T'},
        {label: 'Автобус', value: 'B'},
        {label: 'Самолет', value: 'A'}
    ];
    const handleFirstTimeInCityCheckboxChange = (e, id) => {
        setTravelData(prevData => ({
            ...prevData,
            livings: {
                ...prevData.livings,
                [id]: {
                    ...prevData.livings[id],
                    is_first_time: e.target.checked
                }
            }
        }));
    };
    const handleAccommodationCheckboxChange = (e, id) => {
        setTravelData(prevData => ({
            ...prevData,
            livings: {
                ...prevData.livings,
                [id]: {
                    ...prevData.livings[id],
                    accommodationNeeded: e.target.checked
                }
            }
        }));
        setAccommodationNeeded(prevAccommodationNeeded => {
            const newAccommodationNeeded = {
                ...prevAccommodationNeeded,
                [id]: e.target.checked
            };
            return newAccommodationNeeded;
        });

        if (e.target.checked) {
            // Checkbox is checked, create the item
            setTravelData(prevData => ({
                ...prevData,
                livings: {
                    ...prevData.livings,
                    [id]: {
                        transport: 'A',
                        // TODO: убрать захаржкоженные даты
                        arrival_date: '2024-07-08',
                        departure_date: '2024-07-23',
                        arrival_time: '12:00',
                        is_first_time: true
                    }
                }
            }));
        } else {
            // Checkbox is unchecked, delete the item
            setTravelData(prevData => {
                const newLivings = {...prevData.livings};
                delete newLivings[id];
                return {
                    ...prevData,
                    livings: newLivings
                };
            });
        }
    };
    const onChange = (e) => {
        const {name, value} = e.target;
        const [field, id] = name.split('__');
        if (['arrival_date', 'arrival_time', 'departure_date'].includes(field)) {
            setTravelData(prevData => ({
                ...prevData,
                livings: {
                    ...prevData.livings,
                    [id]: {
                        ...prevData.livings[id],
                        [field]: value
                    }
                }
            }));
        } else if (['accompanying_name', 'motivation', 'comment'].includes(name)) {
            setTravelData(prevData => ({
                ...prevData,
                [name]: value
            }));
        } else if (['citizenship', 'gender', 'number', 'issuer', 'issue_date', 'issuer_code'].includes(name)) {
            setTravelData(prevData => ({
                ...prevData,
                passport: {
                    ...prevData.passport,
                    [name]: value
                }
            }));
        } else {
            // console.log(name, value);
        }
    };

    const onChangeGender = (e, name) => {
        const value = !e ? "" : e.value;
        setTravelData((prevData) => ({
            ...prevData,
            passport: {
                ...prevData.passport,
                [name]: value
            }
        }));
    };

    const onChangePhone = (value) => {
        setTravelData((prevData) => ({
            ...prevData,
            accompanying_phone: value
        }));
    };

    const onChangeTransport = (e, id) => {
        setTravelData(prevData => ({
            ...prevData,
            livings: {
                ...prevData.livings,
                [id]: {
                    ...prevData.livings[id],
                    transport: e.value
                }
            }
        }));
    };
    return (
        <Form onSubmit={onSubmit}>
            <h5>Мотивационное письмо</h5>
            <Form.Text id="motivationHelpBlock" muted>
                Пожалуйста, подробно опишите свою мотивацию для участия в мастерской (минимум 1500 символов).
                Количество комнат для размещения иногородних участников ограничено, и мы, возможно, не
                сможем поддержать всех. Поэтому к заполнению этого пункта следует отнестись максимально
                серьезно.</Form.Text>
            <Form.Group controlId="formTravelMotivation">

                <Form.Control
                    required
                    as="textarea"
                    name="motivation"
                    value={travelData?.motivation}
                    onChange={onChange}
                    rows={15}
                    isInvalid={!!(errors && errors.motivation)}
                />
                <Form.Control.Feedback
                    type="invalid">{errors && errors.motivation && (errors.motivation[0])}</Form.Control.Feedback>

            </Form.Group>

            <h5>Предполагаемые данные о проживании</h5>
            <Form.Text id="accomodationHelpBlock" className="mb-3" muted>
                Обратите внимание, что мы можем покрыть только расходы на проживание. Пожалуйста, обратитесь к своему
                университету, школе или другим возможным источникам финансирования для покрытия расходов на проезд. Ниже
                нужно заполнить предполагаемые данные о проживании и проезде для каждой площадки отдельно.
            </Form.Text>
            {localWorkshops.map((local_workshop, index) => {
                const venue = local_workshop.venue;
                return (
                    <div key={index} className="bmm-card project-card">
                        <div className="project-card__pretitle">{venue.name} ({venue.city})</div>
                        <div><Form.Group controlId={`formTravelAccommodationNeeded${index}`}>

                            <Form.Check
                                className="mt-3 mb-1"
                                type="checkbox"
                                label="Нужна поддержка проживания"
                                checked={accommodationNeeded[local_workshop.id] || false}
                                onChange={(e) => handleAccommodationCheckboxChange(e, local_workshop.id)}
                            />

                        </Form.Group></div>
                        {accommodationNeeded[local_workshop.id] && (
                            <div>

                                <Row className="mt-3">
                                    <Form.Group as={Col} controlId={`formTravelArrivalDate${index}`}>
                                        <Form.Label>Дата заезда</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            name={`arrival_date__${local_workshop.id}`}
                                            value={travelData?.livings && travelData.livings[local_workshop.id] ? travelData.livings[local_workshop.id].arrival_date : ""}
                                            onChange={onChange}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId={`formTravelArrivalTime${index}`}>
                                        <Form.Label>Время заезда</Form.Label>
                                        <Form.Control
                                            required
                                            type="time"
                                            name={`arrival_time__${local_workshop.id}`}
                                            value={travelData?.livings && travelData.livings[local_workshop.id] ? travelData.livings[local_workshop.id].arrival_time : ""}
                                            onChange={onChange}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId={`formTravelDepartureDate${index}`}>
                                        <Form.Label>Дата отъезда</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            name={`departure_date__${local_workshop.id}`}
                                            value={travelData?.livings && travelData.livings[local_workshop.id] ? travelData.livings[local_workshop.id].departure_date : ""}
                                            onChange={onChange}
                                        />
                                    </Form.Group>
                                </Row>
                                <Form.Group controlId={`formTravelTransportType${index}`}>
                                    <Form.Label className={"required mt-3"}>Тип транспорта</Form.Label>
                                    <Select required
                                            name={`transport_${local_workshop.id}`}
                                            value={travelData?.livings && travelData.livings[local_workshop.id] ?
                                                transportOptions.find((option) => option.value === travelData.livings[local_workshop.id].transport) :
                                                null}
                                            onChange={(e) => onChangeTransport(e, local_workshop.id)}
                                            options={transportOptions}
                                            noOptionsMessage={() => 'Тип транспорта не найден'}
                                            components={{IndicatorSeparator: null}}
                                            classNamePrefix="bmm-select"

                                            className="mb-3"
                                            placeholder={null}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors && errors[`transport_${local_workshop.id}`]}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId={`formTravelFirstTimeInCity${index}`}>
                                    <Form.Check
                                        className="mb-1"
                                        type="checkbox"
                                        label={`Первый раз в этом городе`}
                                        checked={travelData?.livings && travelData.livings[local_workshop.id] ? travelData.livings[local_workshop.id].is_first_time : false}
                                        onChange={(e) => handleFirstTimeInCityCheckboxChange(e, local_workshop.id)}
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </div>);
            })}

            <h5 className="mb-3">Данные паспорта</h5>
            <Form.Group controlId="formTravelCitizenship">
                <Form.Label className={"required"}>Гражданство</Form.Label>
                <Form.Control
                    required
                    as="input"
                    name="citizenship"
                    value={travelData?.passport?.citizenship}
                    onChange={onChange}
                    rows={1}
                    isInvalid={!!(errors && errors.citizenship)}
                />
                <Form.Control.Feedback
                    type="invalid">{errors && errors.citizenship && (errors.citizenship[0])}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formTravelGender">
                <Form.Label className={"required"}>Пол</Form.Label>
                <Select required
                        name="gender"
                        value={travelData?.passport?.gender ?
                            genderOptions.find((option) => option.value === travelData.passport.gender) :
                            null}
                        onChange={(e) => onChangeGender(e, 'gender')}
                        options={genderOptions}
                        noOptionsMessage={() => 'Пол не найден'}
                        components={{IndicatorSeparator: null}}
                        classNamePrefix="bmm-select"
                        placeholder={null}
                        isInvalid={!!(errors && errors.gender)}
                />
                <Form.Control.Feedback
                    type="invalid">{errors && errors.gender && (errors.gender[0])}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formTravelNumber">
                <Form.Label className={"required"}>Серия и номер паспорта</Form.Label>
                <InputMask
                    mask="9999 999999"
                    maskChar={null}
                    value={travelData?.passport?.number}
                    onChange={onChange}
                >
                    {(inputProps) => <Form.Control {...inputProps} required as="input" name="number" rows={1}
                     isInvalid={!!(errors && errors.number)}/>}
                </InputMask>

                <Form.Control.Feedback
                    type="invalid">{errors && errors.number && (errors.number[0])}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formTravelIssuer">
                <Form.Label className={"required"}>Кем выдан</Form.Label>
                <Form.Control
                    required
                    as="textarea"
                    name="issuer"
                    value={travelData?.passport?.issuer}
                    onChange={onChange}
                    rows={1}
                     isInvalid={!!(errors && errors.issuer)}
                />
                <Form.Control.Feedback
                    type="invalid">{errors && errors.issuer && (errors.issuer[0])}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formTravelIssueDate">
                <Form.Label className={"required"}>Дата выдачи паспорта</Form.Label>
                <Form.Control
                    required
                    type="date"
                    name="issue_date"
                    value={travelData?.passport?.issue_date}
                    onChange={onChange}
                     isInvalid={!!(errors && errors.issue_date)}
                />
            </Form.Group>
                <Form.Control.Feedback
                    type="invalid">{errors && errors.issue_date && (errors.issue_date[0])}
                </Form.Control.Feedback>


            <Form.Group controlId="formTravelIssuerCode">
                <Form.Label className={"required"}>Код подразделения</Form.Label>
                <InputMask
                    mask="999-999"
                    maskChar={null}
                    value={travelData?.passport?.issuer_code}
                    onChange={onChange}
                >
                    {(inputProps) => <Form.Control {...inputProps} required as="input" name="issuer_code" rows={1}
                    isInvalid={!!(errors && errors.issuer_code)}/>}
                </InputMask>
                <Form.Control.Feedback
                    type="invalid">{errors && errors.issuer_code && (errors.issuer_code[0])}
                </Form.Control.Feedback>
                <Form.Text id="accompanyingHelpBlock" className="mb-3" muted>
                        Если у вас нет этого поля, напишите 000-000 </Form.Text>
            </Form.Group>
            {userAge < 18 && (
                <>
                    <h5>Данные сопровождающего</h5>
                    <Form.Text id="accompanyingHelpBlock" className="mb-3" muted>
                        Поскольку вы еще не достигли совершеннолетия, вам необходимо указать имя и контактный телефон
                        взрослого,
                        который будет сопровождать вас. Это обязательное требование для участия в
                        мероприятии. </Form.Text>
                    <Form.Group controlId="formTravelAccompanyingName" className="mt-3">
                        <Form.Label className={"required"}>ФИО сопровождающего</Form.Label>
                        <Form.Control
                            required
                            as="input"
                            name="accompanying_name"
                            value={travelData?.accompanying_name}
                            onChange={onChange}
                            rows={1}
                            isInvalid={!!(errors && errors.accompanying_name)}
                        />
                        <Form.Control.Feedback
                    type="invalid">{errors && errors.accompanying_name && (errors.accompanying_name[0])}</Form.Control.Feedback>

                    </Form.Group>

                    <Form.Group controlId="formTravelAccompanyingPhone">
                        <Form.Label className={"required"}>Телефон сопровождающего</Form.Label>
                        <PhoneInput
                            required
                            defaultCountry="ru"
                            inputProps={{name: "accompanying_phone"}}
                            value={travelData?.accompanying_phone}
                            onChange={onChangePhone}
                            isInvalid={!!(errors && errors.accompanying_phone)}
                        />
                        <Form.Control.Feedback
                    type="invalid">{errors && errors.accompanying_phone && (errors.accompanying_phone[0])}</Form.Control.Feedback>

                    </Form.Group>
                </>
            )}
            <h5>Комментарии</h5>
            <Form.Group controlId="formTravelComment">
                <Form.Control
                    as="textarea"
                    name="comment"
                    value={travelData?.comment}
                    onChange={onChange}
                    rows={1}
                    isInvalid={!!(errors && errors.comment)}
                />
                <Form.Control.Feedback
                    type="invalid">{errors && errors.comment && (errors.comment[0])}</Form.Control.Feedback>

            </Form.Group>
            {errors && errors.detail && (errors.detail)}
            <Button view="action" type="submit" disabled={submitting} className="mt-3 w-100" size="l">
                {submitting ? submittingButtonName : submitButtonName}
            </Button>

        </Form>
    );
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(TravelForm);