import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";

import {Button} from "@gravity-ui/uikit";

import {
    getAcceptAuthConfig,
    getJSONAcceptAuthConfig,
    USER_APPLICATIONS_ENDPOINT,
    USER_TRAVEL_GRANTS_DETAIL_ENDPOINT,
    USER_TRAVEL_GRANTS_ENDPOINT, WORKSHOPS_INFO_ENDPOINT
} from "../../../api/api";
import {DeleteButtonIcon, EditButtonIcon_Link} from "../../../components/Button/Button";
import {DeleteModal} from "../../../components/Modal/Modals";
import {connect} from "react-redux";
import {TravelStatus} from "../../../components/travelCard/utils";
import LoadingSpinner from "../../../components/LoadingSpinner";

function TravelGrantsPage({user}) {
    const [isLoading, setIsLoading] = useState(true);
    const [travelGrants, setTravelGrants] = useState([]);

    const not_full_user = () => (<>
        <div className="text-danger">Для подачи заявок на тревел грант необходимо указать ФИО и дату рождения <Link
            to="/account/settings">в профиле</Link>, а также подать <Link
            to="/account/application">заявку на участие в мастерской</Link>.
        </div>
    </>);
    const is_bad_user = () => {
        return (!user?.last_name || !user?.first_name || !user?.middle_name || !user?.birth_date)
    }

    const [open, setOpen] = React.useState(false);
    const [applicationData, setApplicationData] = useState(null);

    
    const [activeWorkshop, setActiveWorkshop] = useState(null);
    
    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            axios.get(USER_APPLICATIONS_ENDPOINT, {
                params: {is_active: "True"},
                headers: getJSONAcceptAuthConfig().headers,
            }),
            axios.get(USER_TRAVEL_GRANTS_ENDPOINT, getJSONAcceptAuthConfig()),
            axios
                .get(WORKSHOPS_INFO_ENDPOINT, {
                    params: {is_active: "True"},
                    headers: getJSONAcceptAuthConfig().headers,
                })
                .then(response => {
                    setActiveWorkshop((response.data[0]))
                })
                .catch(error => {
                    console.error('Error getting active workshop data:', error);
                }),
        ])
            .then(([response1, response2]) => {
                setApplicationData(response1.data[0]?.cv);
                setTravelGrants(response2.data);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    const onDelete = async (id) => {
        try {
            await axios.delete(USER_TRAVEL_GRANTS_DETAIL_ENDPOINT(id), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении заявки:', error);
        }
    };

    if (isLoading) {
        return (<>
            <h3 className="mb-2">Тревел грант</h3>
            <hr className="clearfix w-100 pb-0"/>
            <LoadingSpinner/>;
        </>)
    }

    return (<>
            <h3 className="mb-2">Тревел грант</h3>
            <hr className="clearfix w-100 pb-0"/>
            {(((activeWorkshop?.travellers_can_apply
                    && is_bad_user())
                    ||
                    (!applicationData?.participant
                        && activeWorkshop?.travellers_can_apply
                        && travelGrants.length === 0)) && not_full_user())
                ||
                (applicationData?.participant
                    && activeWorkshop?.travellers_can_apply
                    && !travelGrants.some(grant => grant.workshop.name + " " + grant.workshop.year === applicationData?.workshop)) &&
                <div className={"bmm-card project-card"}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className={"project-card__title mt-0"}>
                            {applicationData?.workshop}
                        </h5>
                        {activeWorkshop?.travellers_can_apply &&
                            <Link to={"create_travel"}><Button view={"action"} size={"xl"}>
                                Подать заявку
                            </Button></Link>}
                    </div>
                </div>}
            {travelGrants.length > 0 && travelGrants.map((grant, index) => (
                <div key={index} className={"bmm-card project-card"}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className={"project-card__title mt-0"}>
                            {grant.workshop.name} {grant.workshop.year}
                        </h5>
                        {activeWorkshop?.travellers_can_apply && grant.workshop.name + " " + grant.workshop.year === applicationData?.workshop &&
                            <div className="group-btn">
                                {/*<Link to={`application/${applicationData?.id}`}><Button view={"action"} size={"xl"}>*/}
                                {/*    Редактировать заявку*/}
                                {/*</Button></Link>*/}
                                <DeleteButtonIcon onClick={() => setOpen(true)}/>
                                <EditButtonIcon_Link to={`${grant?.id}`}/>
                            </div>}
                    </div>
                    {grant.livings.length > 0 && grant.livings.map((living, index) => (
                        <div key={index}
                             className={"bmm-card bmm-card_white d-flex align-items-center justify-content-between"}>
                        <span
                            className={"bmm-text_bold"}>{living.local_workshop.venue.name} ({living.local_workshop.venue.city})</span>
                            <TravelStatus status={living.status}/>
                        </div>))}
                    {grant.reviewer_comment &&
                        <div>
                            Комментарий организаторов: {grant.reviewer_comment}
                        </div>}
                    <DeleteModal type="заявку на тревел грант в мастерской " name={`${grant.workshop.name}`}
                                 onDelete={() => onDelete(grant?.id)}
                                 open={open} setOpen={setOpen}/>
                </div>))}


        </>
    );
}

const mapStateToProps = state => ({
    user: state.auth.user,
});
export default connect(mapStateToProps)(TravelGrantsPage);