import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Button, Icon} from "@gravity-ui/uikit";
import {Plus} from "@gravity-ui/icons";
import {Accordion, Form} from "react-bootstrap";
import Select from "react-select";

import {
    getAcceptAuthConfig, getJSONConfig,
    LOCAL_WORKSHOP_DETAIL_ENDPOINT, LOCAL_WORKSHOP_ENDPOINT, USER_LOCAL_WORKSHOP_ENDPOINT,
    VENUE_DETAIL_ENDPOINT,
    VENUES_ENDPOINT
} from "../../api/api";
import {DeleteModal, UpdateModal} from "../../components/Modal/Modals";
import {ProjectPersona} from "../../components/projectCard/utils";
import {AddButtonMaxWidth, DeleteButtonIcon, EditButtonIcon} from "../../components/Button/Button";
import {AsyncSelectField} from "./ProjectFields";
import {getDefaultActiveKey, onAccordionSelect} from "../../utils/accordionUtils";
import {UNDEFINED_VENUE} from "../../utils/const";

const VenueForm = ({submitting, onSubmit, venueData, setVenueData, errors, setOpen}) => {
    const onChange = (e) => {
        const {name, value} = e.target;
        setVenueData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group controlId="formVenueName">
                <Form.Label className="required">Название площадки</Form.Label>
                <Form.Control required
                              type="text"
                              name="name"
                              value={venueData.name}
                              onChange={onChange}
                              autoComplete="off"
                              aria-describedby="nameHelpBlock"
                              isInvalid={!!(errors && errors.name)}/>
                <Form.Control.Feedback type="invalid">{errors && errors.name}</Form.Control.Feedback>
                <Form.Text id="nameHelpBlock" muted>
                    <strong>Например:</strong> НГУ, ТГУ, ТюмГУ и т.д.
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="formVenueCity">
                <Form.Label className="required">Город</Form.Label>
                <Form.Control required
                              type="text"
                              name="city"
                              value={venueData.city}
                              onChange={onChange}
                              aria-describedby="cityHelpBlock"
                              isInvalid={!!(errors && errors.city)}/>
                <Form.Control.Feedback type="invalid">{errors && errors.city}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formVenueAddress">
                <Form.Label className="required">Адрес</Form.Label>
                <Form.Control required
                              type="text"
                              name="address"
                              value={venueData.address}
                              onChange={onChange}
                              autoComplete="off"
                              aria-describedby="addressHelpBlock"
                              isInvalid={!!(errors && errors.address)}/>
                <Form.Control.Feedback type="invalid">{errors && errors.address}</Form.Control.Feedback>
                <Form.Text id="addressHelpBlock" muted>
                    <strong>Например:</strong> ул. Пирогова, 1.
                </Form.Text>
            </Form.Group>
            <div className="line-btn-group">
                <Button view="normal" onClick={() => setOpen(false)} width="max">Отменить</Button>
                <Button view="action" type="submit" disabled={submitting} width="max">
                    {submitting ? "Сохранение..." : "Сохранить"}
                </Button>
            </div>
        </Form>
    );
};

const VenueFormUpdate = ({venue, setOpenUpdate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [venueData, setVenueData] = useState(venue);
    const [errors, setErrors] = useState({
        name: "",
        city: "",
        address: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .patch(VENUE_DETAIL_ENDPOINT(venue.id), venueData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error updating venue data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <VenueForm submitting={submitting} onSubmit={onSubmit}
                   venueData={venueData} setVenueData={setVenueData}
                   errors={errors}
                   setOpen={setOpenUpdate}/>
    );
};

const VenueFormCreate = ({setOpenCreate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [venueData, setVenueData] = useState({
        name: "",
        city: "",
        address: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        city: "",
        address: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .post(VENUES_ENDPOINT(), venueData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error creating venue data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <VenueForm submitting={submitting} onSubmit={onSubmit}
                   venueData={venueData} setVenueData={setVenueData}
                   errors={errors}
                   setOpen={setOpenCreate}/>
    );
};

const Venue = ({venue}) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);

    const onDelete = async (venueId) => {
        try {
            await axios.delete(VENUE_DETAIL_ENDPOINT(venueId), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении площадки:', error);
        }
    };

    return (
        <li className="bmm-card d-flex justify-content-between align-items-center">
            <div className="bmm-text_bold">{venue.name}, {venue.city}, {venue.address}</div>
            <div className="line-btn-group">
                <DeleteButtonIcon onClick={() => setOpenDelete(true)}/>
                <EditButtonIcon onClick={() => setOpenUpdate(true)}/>
            </div>
            <DeleteModal type="площадку" name={`${venue.name}, ${venue.city}, ${venue.address}`}
                         onDelete={() => onDelete(venue.id)}
                         open={openDelete} setOpen={setOpenDelete}/>
            <UpdateModal open={openUpdate} setOpen={setOpenUpdate}>
                <VenueFormUpdate venue={venue} setOpenUpdate={setOpenUpdate}/>
            </UpdateModal>
        </li>
    );
};

const LocalWorkshopForm = ({
                               submitting,
                               onSubmit,
                               workshopName,
                               localWorkshopData,
                               setLocalWorkshopData,
                               errors,
                               setOpen
                           }) => {
    const [venues, setVenues] = useState([]);

    useEffect(() => {
        axios
            .get(VENUES_ENDPOINT(), getJSONConfig())
            .then(response => {
                const newVenues = [...response.data.map((venue) => ({
                    label: `${venue.name}, ${venue.city}, ${venue.address}`, value: venue.id.toString()
                })),];
                setVenues(newVenues);
            });
    }, []);

    const onChange = (e, projectDataField) => {
        const value = !e ? "" : e.value;
        setLocalWorkshopData((prevData) => ({
            ...prevData,
            [projectDataField]: value,
        }));
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group controlId="Workshop">
                <Form.Label className="required">Мастерская</Form.Label>
                <Form.Control required
                              disabled
                              type="text"
                              name="workshop"
                              value={workshopName}/>
            </Form.Group>
            <Form.Group controlId="formVenue">
                <Form.Label className="required">Площадка</Form.Label>
                <Select required
                        name="venue"
                        value={localWorkshopData.venue?.id
                            ? venues.find((venue) => venue.value === localWorkshopData.venue.id.toString())
                            : localWorkshopData.venue
                                ? venues.find((venue) => venue.value === localWorkshopData.venue)
                                : null}
                        onChange={(e) => onChange(e, 'venue')}
                        options={venues}
                        noOptionsMessage={() => 'Площадка не найдена'}
                        components={{IndicatorSeparator: null}}
                        classNamePrefix="bmm-select"
                        placeholder={null}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.venue}</Form.Control.Feedback>
            </Form.Group>
            <AsyncSelectField required={false}
                              controlId="formLocalWorkshopDirector"
                              label="Руководитель площадки"
                              name="director"
                              value={localWorkshopData.director?.email
                                  ? [{
                                      label: localWorkshopData.director.email,
                                      value: localWorkshopData.director.email
                                  }]
                                  : localWorkshopData.director
                                      ? [{
                                          label: localWorkshopData.director,
                                          value: localWorkshopData.director
                                      }]
                                      : null}
                              onChange={onChange}
                              errors={errors}
            />
            <div className="line-btn-group">
                <Button view="normal" onClick={() => setOpen(false)} width="max">Отменить</Button>
                <Button view="action" type="submit" disabled={submitting} width="max">
                    {submitting ? "Сохранение..." : "Сохранить"}
                </Button>
            </div>
        </Form>
    );
};

const LocalWorkshopFormUpdate = ({id, workshopName, localWorkshop, director, setOpenUpdate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [localWorkshopData, setLocalWorkshopData] = useState({
        venue: localWorkshop?.id.toString(),
        director: director?.email,
    });
    const [errors, setErrors] = useState({
        venue: "",
        director: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .patch(LOCAL_WORKSHOP_DETAIL_ENDPOINT(id), localWorkshopData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error updating localWorkshop data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <LocalWorkshopForm submitting={submitting} onSubmit={onSubmit}
                           workshopName={workshopName}
                           localWorkshopData={localWorkshopData} setLocalWorkshopData={setLocalWorkshopData}
                           errors={errors}
                           setOpen={setOpenUpdate}/>
    );
};

const LocalWorkshopFormCreate = ({workshopId, workshopName, setOpenCreate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [localWorkshopData, setLocalWorkshopData] = useState({
        venue: "",
        director: null,
        workshop: workshopId,
    });
    const [errors, setErrors] = useState({
        venue: "",
        director: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .post(LOCAL_WORKSHOP_ENDPOINT, localWorkshopData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error creating localWorkshop data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <LocalWorkshopForm submitting={submitting} onSubmit={onSubmit}
                           workshopName={workshopName}
                           localWorkshopData={localWorkshopData} setLocalWorkshopData={setLocalWorkshopData}
                           errors={errors}
                           setOpen={setOpenCreate}/>
    );
};

const LocalWorkshop = ({workshopName, localWorkshop}) => {
    const {id, venue, director} = localWorkshop;

    const [openDelete, setOpenDelete] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);

    const onDelete = async (localWorkshopId) => {
        try {
            await axios.delete(LOCAL_WORKSHOP_DETAIL_ENDPOINT(localWorkshopId), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            console.error('Ошибка при удалении площадки из мастерской:', error);
        }
    };

    return (
        <li className="local-workshop-item">
            <div className="d-flex justify-content-between align-items-center">
                <div className="bmm-text_bold">
                    {venue
                        ? `${venue.name}, ${venue.city}, ${venue.address}`
                        : <div className="bmm-text_gray">{UNDEFINED_VENUE}</div>}
                </div>
                <div className="line-btn-group">
                    <DeleteButtonIcon onClick={() => setOpenDelete(true)}/>
                    <EditButtonIcon onClick={() => setOpenUpdate(true)}/>
                </div>

                <DeleteModal type="площадку" name={venue && `${venue.name}, ${venue.city}, ${venue.address}`}
                             onDelete={() => onDelete(id)}
                             open={openDelete} setOpen={setOpenDelete}
                             additionalText={`с мастерской ${workshopName}`}/>
                <UpdateModal open={openUpdate} setOpen={setOpenUpdate}>
                    <LocalWorkshopFormUpdate id={id} workshopName={workshopName} localWorkshop={venue}
                                             director={director}
                                             setOpenUpdate={setOpenUpdate}/>
                </UpdateModal>
            </div>
            {director && <ProjectPersona user={director}/>}
        </li>
    );
};

const Workshop = ({workshop, localWorkshops}) => {
    const [openCreate, setOpenCreate] = useState(false);

    return (
        <Accordion.Item eventKey={`workshopNoV-${workshop.id}`}>
            <Accordion.Header>
                <h5 className="m-0">{workshop.name} {workshop.year}</h5>
            </Accordion.Header>
            <Accordion.Body className="local-workshop-list">
                <AddButtonMaxWidth className={"mb-3"} text={"Добавить площадку на мастерскую"}
                                   onClick={() => setOpenCreate(true)}/>
                <UpdateModal open={openCreate} setOpen={setOpenCreate}>
                    <LocalWorkshopFormCreate workshopName={`${workshop.name} ${workshop.year}`} workshopId={workshop.id}
                                             setOpenCreate={setOpenCreate}/>
                </UpdateModal>
                <ul className="list-unstyled mb-0">
                    {localWorkshops.map((localWorkshop) => (
                        <LocalWorkshop key={localWorkshop.id} workshopName={`${workshop.name} ${workshop.year}`}
                                       localWorkshop={localWorkshop}/>
                    ))}
                </ul>
            </Accordion.Body>
        </Accordion.Item>
    );
};

function VenueReviewList() {
    const [venues, setVenues] = useState([]);
    const [openCreate, setOpenCreate] = useState(false);
    const [workshops, setWorkshops] = useState([]);

    useEffect(() => {
        axios
            .get(VENUES_ENDPOINT(), getAcceptAuthConfig())
            .then(response => {
                response.data?.sort((a, b) => a.name.localeCompare(b.name));
                setVenues(response.data);
            })
            .catch(error => {
                console.error('Error getting venues data:', error);
            });

        axios
            .get(USER_LOCAL_WORKSHOP_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                response.data.forEach(item => {
                    item.local_workshops?.sort((a, b) => a.venue?.name.localeCompare(b.venue?.name));
                });
                setWorkshops(response.data);
            })
            .catch(error => {
                console.error('Error getting local_workshops data:', error);
            });
    }, []);

    return (
        <>
            <h3 className="mb-2">Площадки на мастерских</h3>
            <hr className="clearfix w-100 pb-0"/>
            <Accordion className="about-accordion"
                       alwaysOpen
                       defaultActiveKey={getDefaultActiveKey("expandedWorkshopNoV")}
                       onSelect={(e) => onAccordionSelect(e, "expandedWorkshopNoV")}>
                {workshops.map(({workshop, local_workshops}) => (
                    <Workshop key={workshop.id} workshop={workshop} localWorkshops={local_workshops}/>
                ))}
            </Accordion>

            <h3 className="mt-5 mb-2">Площадки</h3>
            <hr className="clearfix w-100 pb-0"/>
            <AddButtonMaxWidth text={"Добавить площадку"} onClick={() => setOpenCreate(true)}/>
            <UpdateModal open={openCreate} setOpen={setOpenCreate}>
                <VenueFormCreate setOpenCreate={setOpenCreate}/>
            </UpdateModal>
            <ul className="list-unstyled mb-0">
                {venues.map((venue) => (
                    <Venue key={venue.id} venue={venue}/>
                ))}
            </ul>
        </>
    );
}

export default VenueReviewList;