import React from 'react';


export function Diagram() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M 3.5 14 h -1 a 2 2 0 0 1 -2 -2 v -2 a 2 2 0 0 1 2 -2 h 2 c 0.173 0 0.34 0.022 0.5 0.063 v -4.063 a 2 2 0 0 1 2 -2 h 2 c 0 0 2 0 2 2 v 1.063 c 0.16 -0.041 0.327 -0.063 0.5 -0.063 h 2 a 2 2 0 0 1 2 2 v 5 a 2 2 0 0 1 -2 2 h -10 Z M 13.5 12.5 A 0.5 0.5 0 0 0 14 12 v -5 a 0.5 0.5 0 0 0 -0.5 -0.5 h -2 a 0.5 0.5 0 0 0 -0.5 0.5 v 5 a 0.5 0.5 0 0 0 0.5 0.5 h 2 Z M 9 12.5 A 0.5 0.5 0 0 0 9.5 12 V 4 a 0.5 0.5 0 0 0 -0.5 -0.5 H 7 a 0.5 0.5 0 0 0 -0.5 0.5 v 8 a 0.5 0.5 0 0 0 0.5 0.5 h 2 Z M 4.5 12.5 A 0.5 0.5 0 0 0 5 12 v -2 a 0.5 0.5 0 0 0 -0.5 -0.5 h -2 a 0.5 0.5 0 0 0 -0.5 0.5 v 2 a 0.5 0.5 0 0 0 0.5 0.5 h 2 Z"/>
        </svg>
    );
}

export function Load() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M12.442 13.033c-.278.307-.319.777-.05 1.092.27.314.747.353 1.033.053a7.5 7.5 0 1 0-10.85 0c.286.3.763.261 1.032-.053.27-.315.23-.785-.05-1.092a6 6 0 1 1 8.884 0Z"/>
        </svg>
    );
}

export function EmptyFile() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M 11 13.5 h -6 a 1.5 1.5 0 0 1 -1.5 -1.5 V 4 A 1.5 1.5 0 0 1 5 2.5 L 7.759 2.5 A 1.5 1.5 0 0 1 8.819 2.939 L 12.06 6.182 A 1.5 1.5 0 0 1 12.5 7.243 V 12 A 1.5 1.5 0 0 1 11 13.5 Z M 14 7.243 A 3 3 0 0 0 13.121 5.121 L 9.88 1.88 A 3 3 0 0 0 7.757 1 H 5 a 3 3 0 0 0 -3 3 v 8 a 3 3 0 0 0 3 3 h 6 a 3 3 0 0 0 3 -3 V 7.243 Z "/>
        </svg>
    );
}

export function CircleGrip() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path fill="currentColor"
                d="M 8 15 A 7 7 0 1 0 8 1 a 7 7 0 0 0 0 14 Z M 8 13.5 a -5.5 -5.5 0 0 1 0 -11 a 5.5 5.5 0 1 1 0 11 Z M 7.5 5.625 a 0.768 0.768 90 1 1 -1.536 0 a 0.768 0.768 90 0 1 1.536 0 Z M 6.7 8.825 a 0.768 0.768 90 1 0 0 -1.536 a 0.768 0.768 90 0 0 0 1.536 Z M 9.2 8.825 a 0.768 0.768 90 1 0 0 -1.536 a 0.768 0.768 90 0 0 0 1.536 Z M 9.2 6.425 a 0.768 0.768 90 1 0 0 -1.536 a 0.768 0.768 90 0 0 0 1.536 Z M 7.5 10.425 a 0.768 0.768 90 1 1 -1.536 0 a 0.768 0.768 90 0 1 1.536 0 Z M 9.2 11.225 a 0.768 0.768 90 1 0 0 -1.536 a 0.768 0.768 90 0 0 0 1.536 Z"/>
        </svg>
    );
}

export function EditSlash() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                d="M 11.423 1 A 3.577 3.577 0 0 1 15 4.577 c 0 0.27 -0.108 0.53 -0.3 0.722 l -0.528 0.529 L 11.6 8.4 L 10.57 7.37 L 10.992 6.888 A 2.077 2.077 0 0 0 9.113 5.009 L 8.63 5.43 L 7.63 4.43 L 10.701 1.299 c 0.191 -0.191 0.451 -0.299 0.722 -0.299 Z M 10.333 3.788 a 5.59 3.59 0 0 1 1.878 1.879 l 1.28 -1.28 C 13.4 3.392 12.608 2.598 11.613 2.507 l -1.28 1.28 Z M 9.5 10.5 L 7.142 12.858 a 3 3 0 0 1 -1.533 0.82 l -2.638 0.528 a 1 1 0 0 1 -1.177 -1.177 l 0.528 -2.638 a 3 3 0 0 1 0.82 -1.533 L 5.5 6.5 L 1.97 3.03 a 0.75 0.75 0 0 1 1.06 -1.06 l 11 11 a 0.75 0.75 0 1 1 -1.06 1.06 L 9.5 10.5 M 6.5 7.5 L 4.203 9.919 a 1.5 1.5 0 0 0 -0.41 0.766 l -0.38 1.903 l 1.902 -0.38 a 1.5 1.5 0 0 0 0.767 -0.41 L 8.5 9.5 Z"/>
        </svg>
    );
}