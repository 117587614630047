import React from 'react';
import './Timeline.css';

import {Button, Icon} from '@gravity-ui/uikit';
import {CircleCheck, CirclePlus, Clock, Xmark} from '@gravity-ui/icons';
import {CircleGrip} from "../Icons/Icons";

const Timeline = ({position = 'left', children}) => {
    return (
        <div className={"timeline left"}>
            {children}
        </div>
    );
};

const TimelineItem = ({children, completed}) => {
    return <div className={`timeline-item ${completed ? 'timeline-item-completed' : ''}`}>
        <Icon data={completed ? CircleCheck : Clock} className={"timeline-icon"}/>
        {children}
    </div>;
};

Timeline.Item = TimelineItem;

const TimelineDraggableItem = React.forwardRef(({children, attributes, listeners, style, onDelete}, ref) => {
    return (
        <div className={"timeline-item timeline-draggable-item w-100"} ref={ref} style={style}>
            <div className={"cursor-drag m-0 timeline-item__action"} {...attributes} {...listeners}>
                <Icon data={CircleGrip} className={"timeline-icon"}/>
            </div>
            <Button onClick={onDelete} className={"timeline-delete-button"}>
                <Icon data={Xmark} className={"timeline-delete-icon"}/>
            </Button>
            {children}
        </div>
    );
});

Timeline.DraggableItem = TimelineDraggableItem;

const TimelineAddItem = ({children, onClick}) => {
    return <div className={"timeline-item"}>
        <Button onClick={onClick} className={"timeline-add-button"}><CirclePlus/></Button>
        {children}
    </div>;
};

Timeline.AddItem = TimelineAddItem;

const TimelineDescription = ({children, className = ""}) => {
    return <div className={`timeline-item__description ${className}`}>{children}</div>;
};

Timeline.Description = TimelineDescription;

const TimelineDate = ({children}) => {
    return <div className={"timeline-item__date"}>{children}</div>;
};

Timeline.Date = TimelineDate;

const TimelineTitle = ({children}) => {
    return <div className={"bmm-subtitle"}>{children}</div>;
};

Timeline.Title = TimelineTitle;

const TimelineHTMLText = ({children}) => {
    return <div className="bmm-text" dangerouslySetInnerHTML={{__html: children || ''}}/>
};

Timeline.HTMLText = TimelineHTMLText;

const TimelineText = ({children, hasForm = false}) => {
    return <div className={`${hasForm ? "" : "bmm-text"} `}>{children}</div>;
};

Timeline.Text = TimelineText;

export default Timeline;
