import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";

import {Button} from "@gravity-ui/uikit";

import {getAcceptAuthConfig, STAGE_ENDPOINT} from "../../../api/api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import WorkshopStagesForm from "./WorkshopStagesForm";

function WorkshopStagesUpdate() {
    const {id} = useParams();

    const [submitting, setSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [stages, setStages] = useState([]);

    useEffect(() => {
        axios
            .get(STAGE_ENDPOINT(id), getAcceptAuthConfig())
            .then(response => {
                setStages(response.data);
            })
            .catch(error => {
                console.error('Не удалось получить этапы:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .post(STAGE_ENDPOINT(id), stages, getAcceptAuthConfig())
            .then(response => {
                setStages(response.data);
            })
            .catch(error => {
                console.error('Не удалось сохранить этапы:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <h3 className="mb-2">Этапы</h3>
            <hr className="clearfix w-100 pb-0"/>

            {isLoading &&
                <LoadingSpinner/> ||
                <>
                    <WorkshopStagesForm stages={stages} setStages={setStages} workshopId={id}/>

                    <Button view="action" type="button" className="mt-3 w-100" size="l"
                            disabled={submitting} onClick={onSubmit}>
                        {submitting ? "Сохранение..." : "Сохранить"}
                    </Button>
                </>
            }
        </>
    );
}

export default WorkshopStagesUpdate;