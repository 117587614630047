import React from 'react';
import {Link} from "react-router-dom";
import {Accordion} from "react-bootstrap";

import {Button, Icon} from "@gravity-ui/uikit"
import {ArrowUpRightFromSquare} from "@gravity-ui/icons";

import ProjectStatus, {
    ProjectDate,
    ProjectForSchool,
    ProjectKeywords,
    ProjectPersona,
    ProjectPersonaWithModal,
    ProjectVenue,
    ProjectWorkshop
} from "./utils"
import './ProjectCard.css'
import {
    REFLECTIONS_MANAGER_DETAIL_ENDPOINT,
    REFLECTIONS_MANAGER_ENDPOINT,
    REVIEW_PROJECT_DETAIL_ENDPOINT
} from "../../api/api";
import ReflectionUpload from "../ReflectionUpload";
import ResultsApplication from "../ResultsApplication";

function ProjectManagerCard({
                                project,
                                templates,
                                userFiles,
                                setUserFiles,
                                submitting,
                                setSubmitting,
                                with_bmm_name = false
                            }) {
    return (
        <div className="bmm-card project-card project-card-draft">
            <div className="project-card__pretitle">
                <div className="me-1">
                    {with_bmm_name && <ProjectWorkshop workshop={project.workshop}/>}
                    <ProjectVenue venue={project.venue}/>
                </div>
                <div>
                    <ProjectForSchool is_for_schoolchildren={project.is_for_schoolchildren}/>
                    <ProjectStatus status={project.status}/>
                </div>
            </div>

            <span className="project-card__title">{project.name}</span>

            <div className="project__keywords project-card__keywords">
                <ProjectKeywords keywords={project.keywords}/>
            </div>

            <div className="project-card__text" dangerouslySetInnerHTML={{__html: project.description}}/>

            {project.workers?.length > 0 &&
                <Accordion className={"mt-1 mb-3 workers-accordion"} flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Участники проекта</Accordion.Header>
                        <Accordion.Body className={"four-columns-container"}>
                            {project.workers.map((worker, index) => (
                                <div key={index}
                                     className={`bmm-card bmm-card_white distribution-participant-card ${worker.participation_confirmation === 'confirmed' && "background-confirmed" || worker.participation_confirmation === 'declined' && "background-declined"}`}>
                                    <ProjectPersonaWithModal key={index} user={worker}
                                                             projectId={project.id}/>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }

            {templates && <ReflectionUpload templates={templates}
                                            userFiles={userFiles} setUserFiles={setUserFiles}
                                            submitting={submitting} setSubmitting={setSubmitting}
                                            projectId={project.id}
                                            detailEndpoint={REFLECTIONS_MANAGER_DETAIL_ENDPOINT}
                                            endpoint={REFLECTIONS_MANAGER_ENDPOINT}
            />}

            <ResultsApplication projectData={project} submitting={submitting} setSubmitting={setSubmitting}
                                detailEndpoint={REVIEW_PROJECT_DETAIL_ENDPOINT}/>

            <div className="project-card__subtitle">
                <div className="project-card__subtitle-date">
                    <ProjectDate date={new Date(project.created_on)}/>
                </div>
                <div className="group-btn">
                    <Link to={`/project/${project.id}`}>
                        <Button view={"normal"} size={"m"} title={"Предпросмотр"}>
                            <Icon data={ArrowUpRightFromSquare} size={18}/>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ProjectManagerCard;