import React from 'react';
import {Routes, Route} from 'react-router-dom';


import {Bell, Person, Lock, PencilToSquare, FileText, ThumbsUp2, Pencil} from "@gravity-ui/icons";

import AboutPage from './pages/main/AboutPage';
import ProjectsPage from './pages/main/ProjectsPage'
import StagesPage from './pages/main/StagesPage'
import ArchivePage from './pages/main/ArchivePage';
import HelpPage from './pages/main/HelpPage';
import AccountMenuPage from './pages/accountPage/AccountMenuPage';
import UserFormUpdate from "./pages/accountPage/UserFormUpdate";
import ProjectUserList from "./pages/accountPage/ProjectUserList";
import LoginPage from './pages/auth/LoginPage';
import RegistrationPage from "./pages/auth/RegistrationPage";
import ActivationPage from "./pages/auth/ActivationPage";
import ProjectUserFormCreate from "./pages/accountPage/ProjectUserFormCreate";
import ProjectUserFormUpdate from "./pages/accountPage/ProjectUserFormUpdate";
import ProjectReviewList from "./pages/accountPage/ProjectReviewList";
import ResetPassword from "./pages/auth/ResetPassword"
import ResetPasswordConfirm from "./pages/auth/ResetPasswordConfirm";

import {connect} from 'react-redux';
import Layout from './hocs/Layout';
import ContactsPage from "./pages/main/ContactsPage";
import PrivateRoute from "./utils/PrivateRoute";
import ProjectReviewUpdate from "./pages/accountPage/ProjectReviewUpdate";
import StreamReviewList from "./pages/accountPage/StreamReviewList";
import VenueReviewList from "./pages/accountPage/VenueReviewList";

import CanCreateProject from './utils/CanCreateProject';
import CanManage from "./utils/CanManage";
import CreateUser from "./pages/accountPage/CreateUser";
import EditUser from "./pages/accountPage/EditUser";
import AllUsersList from "./pages/accountPage/AllUsersList";
import ProjectPage from "./pages/main/ProjectPage";
import ParticipantPage from "./pages/accountPage/Participant/ParticipantPage";
import ApplicationFormCreate from "./pages/accountPage/Participant/ApplicationFormCreate";
import ApplicationFormUpdate from "./pages/accountPage/Participant/ApplicationFormUpdate";
import TravelGrantsPage from "./pages/accountPage/TravelGrant/TravelGrantsPage";
import TravelGrantsList from "./pages/accountPage/TravelGrant/TravelGrantsList";
import TravelFormCreate from "./pages/accountPage/TravelGrant/TravelFormCreate";
import TravelFormUpdate from "./pages/accountPage/TravelGrant/TravelFormUpdate";
import CanCreateTravel from "./utils/CanCreateTravel";
import CanUpdateTravel from "./utils/CanUpdateTravel";
import CanCreateApplication from "./utils/CanCreateApplication";
import CanUpdateApplication from "./utils/CanUpdateApplication";
import ProjectManagerList from "./pages/accountPage/Manager/ProjectManagerList";
import ProjectManagerSelection from "./pages/accountPage/Manager/ProjectManagerSelection";
import ProjectManagerSelectionForm from "./pages/accountPage/Manager/ProjectManagerSelectionForm";
import DirectorProjectApplicationsPage from "./pages/accountPage/Director/DirectorProjectApplicationsPage";
import DirectorProjectApplicationView from "./pages/accountPage/Director/DirectorProjectApplicationView";
import WorkshopDirectorDistributionPage from "./pages/accountPage/Director/WorkshopDirectorDistributionPage";
import DirectorApplicationFormCreate from "./pages/accountPage/Director/DirectorApplicationFormCreate";
import DirectorApplicationFormUpdate from "./pages/accountPage/Director/DirectorApplicationFormUpdate";
import DirectorApplicationForm from "./pages/accountPage/Director/DirectorApplicationForm";
import ProjectParticipantList from "./pages/accountPage/Participant/ProjectParticipantList";
import ReflectionReviewList from "./pages/accountPage/Director/ReflectionReviewList";
import WorkshopReviewList from "./pages/accountPage/Director/WorkshopReviewList";
import WorkshopStagesUpdate from "./pages/accountPage/Director/WorkshopStagesUpdate";
import WorkshopAboutUpdate from "./pages/accountPage/Director/WorkshopAboutUpdate";


const commonNavItems = {
    startItems: [
        {link: 'settings', element: <UserFormUpdate/>, label: 'Редактирование профиля', visual: <Person/>},
        {link: 'notifications', element: <div>Уведомления</div>, label: 'Уведомления', visual: <Bell/>, disabled: true},
    ],
    items: [],
    endItems: [
        {link: 'reset', element: <div>Сброс пароля</div>, label: 'Смена пароля', visual: <Lock/>, disabled: true},
    ],
};

const userItems = [
    {
        link: 'reflection',
        element: <div>Мои рефлексии</div>,
        label: 'Мои рефлексии',
        visual: <PencilToSquare/>,
        disabled: true
    },
    {
        link: 'recommendation',
        element: <div>Мои рекомендации</div>,
        label: 'Мои рекомендации',
        visual: <ThumbsUp2/>,
        disabled: true
    },
    {link: 'cv', element: <div>Мои резюме</div>, label: 'Мои резюме', visual: <FileText/>, disabled: true},
];

const managerItems = [
    {
        link: 'travel-management',
        element: <div>Управление травел грантами</div>,
        label: 'Управление травел грантами',
        visual: <Pencil/>,
        disabled: true
    },
    {
        link: 'project-management',
        element: <ProjectReviewList/>,
        label: 'Управление проектами',
        visual: <Pencil/>,
        disabled: true
    },
];

const streamManagerItems = [
    ...managerItems,
    {
        link: 'stream-management',
        element: <div>Управление потоками</div>,
        label: 'Управление потоками',
        visual: <Pencil/>,
        disabled: true
    },
];

const venueManagerItems = [
    ...streamManagerItems,
    {
        link: 'venue-management',
        element: <div>Управление площадками</div>,
        label: 'Управление площадками',
        visual: <Pencil/>,
        disabled: true
    },
];

const workshopManagerItems = [
    ...venueManagerItems,
    {
        link: 'workshop-management',
        element: <div>Управление мастерской</div>,
        label: 'Управление мастерской',
        visual: <Pencil/>,
        disabled: true
    },
];

const menuItems = [
    {link: 'settings', element: <UserFormUpdate/>, label: 'Редактирование профиля', visual: <Person/>},
    {link: 'notifications', element: <div>Уведомления</div>, label: 'Уведомления', visual: <Bell/>},
    {link: 'project', element: <ProjectUserList/>, label: 'Мои проекты', visual: null},
];

commonNavItems.items = [...userItems];

function App({user}) {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path="about" element={<AboutPage/>}/>
                <Route index element={<ProjectsPage key="projects" archive={false}/>}/>
                <Route path="archived_projects" element={<ProjectsPage key="archived_projects" archive={true}/>}/>
                <Route path="project/:id" element={<ProjectPage/>}/>
                <Route path="stages" element={<StagesPage/>}/>
                <Route path="archive" element={<ArchivePage/>}/>
                <Route path="help" element={<HelpPage/>}/>
                <Route path="contacts" element={<ContactsPage/>}/>

                <Route path="about/:id" element={
                    <CanManage has_rights={user && user.is_ws_director}>
                        <WorkshopAboutUpdate/>
                    </CanManage>}
                />

                <Route path="account" element={
                    <PrivateRoute> <AccountMenuPage menuItems={commonNavItems}/></PrivateRoute>
                }>
                    {/*<Route path="settings" element={<UserFormUpdate/>}/>*/}

                    <Route path="participant" element={<ProjectParticipantList/>}/>

                    <Route path="application" element={<ParticipantPage/>}/>

                    <Route path="application/create_application"
                           element={<CanCreateApplication><ApplicationFormCreate/></CanCreateApplication>}/>

                    <Route path="application/:id"
                           element={<CanUpdateApplication><ApplicationFormUpdate/></CanUpdateApplication>}/>

                    <Route path="travel" element={<TravelGrantsPage/>}/>

                    <Route path="travel/create_travel"
                           element={<CanCreateTravel><TravelFormCreate/></CanCreateTravel>}/>

                    <Route path="travel/:id" element={<CanUpdateTravel><TravelFormUpdate/></CanUpdateTravel>}/>

                    {menuItems.map(({link, element}) => (
                        <Route path={link} key={link} element={element}/>
                    ))}

                    <Route path="curator" element={<ProjectManagerList/>}/>

                    <Route path="selection" element={<ProjectManagerSelection/>}/>
                    <Route path="selection/:id" element={<ProjectManagerSelectionForm/>}/>

                    <Route path="project/create"
                           element={<CanCreateProject><ProjectUserFormCreate/></CanCreateProject>}/>
                    <Route path="project/:id" element={<ProjectUserFormUpdate/>}/>


                    <Route path="all-applications" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <DirectorProjectApplicationsPage/>
                        </CanManage>}
                    />

                    <Route path="reflection-review" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <ReflectionReviewList/>
                        </CanManage>}
                    />

                    <Route path="distribution" element={
                        <CanManage has_rights={user && user.is_ws_director}>
                            <WorkshopDirectorDistributionPage/>
                        </CanManage>}
                    />

                    <Route path="all-applications/:id" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <DirectorProjectApplicationView/>
                        </CanManage>}
                    />

                    <Route path="project-management" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager || user.permissions.some(permission => permission.action.codename === 'list_all_projects'))}>
                            <ProjectReviewList/>
                        </CanManage>}
                    />
                    <Route path="project-management/:id" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <ProjectReviewUpdate/>
                        </CanManage>}
                    /> // TODO: проверять права на редактирование конкретного проекта у руководителей

                    <Route path="stream-management" element={
                        <CanManage has_rights={user && (user.is_ws_director || user.is_lws_director)}>
                            <StreamReviewList/>
                        </CanManage>
                    }/>

                    <Route path="venue-management" element={
                        <CanManage has_rights={user && user.is_ws_director}>
                            <VenueReviewList/>
                        </CanManage>}
                    />

                    <Route path="workshop-management" element={
                        <CanManage has_rights={user && user.is_ws_director}>
                            <WorkshopReviewList/>
                        </CanManage>}
                    />

                    <Route path="workshop-management/about/:id" element={
                        <CanManage has_rights={user && user.is_ws_director}>
                            <WorkshopAboutUpdate/>
                        </CanManage>}
                    />

                    <Route path="workshop-management/stages/:id" element={
                        <CanManage has_rights={user && user.is_ws_director}>
                            <WorkshopStagesUpdate/>
                        </CanManage>}
                    />

                    <Route path="travel-grants" element={
                        <CanManage has_rights={user && user.is_ws_director}>
                            <TravelGrantsList/>
                        </CanManage>}
                    />

                    <Route path="users" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <AllUsersList/>
                        </CanManage>
                    }/>

                    <Route path="users/:id" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <EditUser/>
                        </CanManage>}
                    />

                    <Route path="users/:id/applications" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <DirectorApplicationForm/>
                        </CanManage>}
                    />

                    <Route path="users/:id/applications/create_application" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <DirectorApplicationFormCreate/>
                        </CanManage>}
                    />

                    <Route path="users/:user_id/applications/:id" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <DirectorApplicationFormUpdate/>
                        </CanManage>}
                    />

                    <Route path="users/create-user" element={
                        <CanManage
                            has_rights={user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)}>
                            <CreateUser/>
                        </CanManage>}
                    />


                </Route>

                <Route path="signin" element={<LoginPage/>}/>
                <Route path="signup" element={<RegistrationPage/>}/>
                <Route path='password_reset' element={<ResetPassword/>}/>
                <Route path='password_reset/:uid/:token' element={<ResetPasswordConfirm/>}/>
                <Route path="activation/:uid/:token" element={<ActivationPage/>}/>
            </Route>
        </Routes>
    );
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(App);
