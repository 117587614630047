import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";

import ProjectUserForm from "./ProjectUserForm";
import {getAcceptAuthConfig, getJSONAcceptAuthConfig, PROJECT_DETAIL_ENDPOINT} from "../../api/api";

function ProjectUserFormUpdate() {
    const {id} = useParams();

    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const [hasManager, setHasManager] = useState("");
    const [projectData, setProjectData] = useState({
        venue: "-1",
        name: "",
        description: "",
        requirements: "",
        is_for_schoolchildren: "",
        full_description_file: "",
        test_file: "",
        status: "",
        local_stream: "",
        customers: [],
        results_file: "",
        keywords: [],
        managers: [],
        manager_requirements: "",
        description_text_editor: "",
    });

    const [oldFiles, setOldFiles] = useState({
        full_description_file: "", test_file: ""
    });

    const [errors, setErrors] = useState({
        venue: "",
        name: "",
        description: "",
        requirements: "",
        is_for_schoolchildren: "",
        full_description_file: "",
        test_file: "",
        status: "",
        local_stream: "",
        keywords: "",
        manager: "",
        customers: [],
        managers: [],
    });

    useEffect(() => {
        axios
            .get(PROJECT_DETAIL_ENDPOINT(id), getJSONAcceptAuthConfig())
            .then(response => {
                if (response.data.status !== 'submitted' && response.data.status !== 'returned') {
                    navigate('../', {relative: "path"});
                } else {
                    setProjectData({
                        ...response.data,
                        venue: response.data.venue ? response.data.venue.id.toString() : "-1",
                        description_text_editor: response.data.description,
                    });
                    setOldFiles(response.data);
                    setHasManager(Boolean(response.data.managers));
                }
            })
            .catch(error => {
                console.error('Error getting project data:', error);
            });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        let projectData_copy = {...projectData};

        const tempElement = document.createElement('div');
        tempElement.innerHTML = projectData_copy.description;

        if (!projectData_copy.description || projectData_copy.description.trim() === '' || tempElement.textContent.trim() === '') {
            setErrors((prevErrors) => ({
                    ...prevErrors,
                    description: 'Заполните это поле.'
            }));
            document.querySelector('[for="formProjectDescriptionShort"]').scrollIntoView({ behavior: 'smooth' })
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                description: ''
            }));

            setSubmitting(true);

            if (projectData_copy.full_description_file === oldFiles.full_description_file) {
                delete projectData_copy.full_description_file;
            }
            if (projectData_copy.test_file === oldFiles.test_file) {
                delete projectData_copy.test_file;
            }
            if (projectData_copy.results_application_file === oldFiles.results_application_file) {
                delete projectData_copy.results_application_file;
            }
            if (!projectData_copy.results_application_url) {
                delete projectData_copy.results_application_url;
            }
            if (projectData_copy.results_url != '' && !projectData_copy.results_url) {
                delete projectData_copy.results_url;
            }
            if (!hasManager) {
                delete projectData_copy.managers;
            }
            if (projectData_copy.customers?.length <=0) {
                delete projectData_copy.customers;
            }
            if (projectData_copy.venue?.id) {
                projectData_copy.venue = projectData_copy.venue.id;
            }
            delete projectData_copy.status;
            delete projectData_copy.results_file;
            delete projectData_copy.local_stream;

            const formDataToSend = new FormData();
            for (const key in projectData_copy) {
                if (key === 'managers' || key === 'customers') {
                    formDataToSend.append(key, JSON.stringify(projectData_copy[key]));
                } else {
                    formDataToSend.append(key, projectData_copy[key]);
                }
            }

            axios
                .patch(PROJECT_DETAIL_ENDPOINT(id), formDataToSend, getAcceptAuthConfig())
                .then(response => {
                    navigate('../', {relative: "path"});
                })
                .catch(error => {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        ...JSON.parse(error.response.request.response)
                    }));
                    console.error('Error updating user data:', error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    const onCanselClick = (e) => {
        navigate('../', {relative: "path"});
    }

    return (<>
        <h3 className="mb-2">Редактирование проекта</h3>
        <hr className="clearfix w-100 pb-0"/>

        <ProjectUserForm type={"update"}
                         onSubmit={onSubmit} submitting={submitting}
                         projectData={projectData} setProjectData={setProjectData}
                         hasManager={hasManager} setHasManager={setHasManager}
                         oldFiles={oldFiles}
                         errors={errors} setErrors={setErrors}
                         onCanselClick={onCanselClick}/>
    </>);
}

const mapStateToProps = state => ({
    user: state.auth.user, access: state.auth.access,
});

export default connect(mapStateToProps)(ProjectUserFormUpdate);