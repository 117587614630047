import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {connect} from "react-redux";
import {Form} from "react-bootstrap";

import {Icon, Button, Select, Label} from '@gravity-ui/uikit';
import {
    ArrowDownToSquare,
    FileArrowDown,
    FileCheck,
    FileText,
    PersonXmark,
    Shapes3, SquareListUl,
    SquareXmark
} from "@gravity-ui/icons";

import LoadingSpinner from "../../../components/LoadingSpinner";
import {
    FIX_DISTRIBUTION_ENDPOINT,
    getAcceptAuthConfig, getJSONAcceptAuthConfig, getJSONConfig,
    REFLECTIONS_TEMPLATE_DETAIL_ENDPOINT,
    REFLECTIONS_TEMPLATES_ENDPOINT, STREAMS_ENDPOINT, VENUES_ENDPOINT
} from "../../../api/api";
import {getThemeByPriority, openFileInNewTab} from "../../../utils/utils";
import {HoverableIconLikeButton, IconLikeButton} from "../../../components/Button/Button";
import {REFLECTION_FORMATS, UNALLOCATED_PROJECTS} from "../../../utils/const";


const ParticipantCard = ({participant, currentProjectId}) => {

    const project = Array.isArray(participant.projects) ? participant.projects?.find(proj => proj.id === currentProjectId) : null;

    return (
        <div
            className={`bmm-card bmm-card_white distribution-participant-card`}>
            <div className={'d-flex align-items-center'}>
                <Label theme="info" className={"grade-label me-1"}>{project ? project.grade : '-'}</Label>
                <Label theme={project?.priority !== undefined ? getThemeByPriority(project.priority) : "danger"}
                       className={"priority-label me-1"}>
                    {project ? project.priority + 1 : '-'}
                </Label>

                <span className={"single-line-text"}>
                    {participant.name}
                </span>
            </div>
        </div>
    );
};

const Project = ({project, className}) => {
    return (
        <div className={`bmm-card project-card ${className || ''}`}>
            {project.id > 0 ?
                <>
                    <div className={"d-flex flex-row-reverse align-items-center justify-content-between mb-1"}>
                        <div>

                        </div>
                        {project.venue &&
                            <div className={"mb-1 d-flex align-items-center"}>
                                <Label theme={"unknown"}>
                                    {project.venue.name === 'Омский филиал ИМ СО РАН' ? project.venue.city : project.venue.name}
                                </Label>
                                {project.is_for_schoolchildren &&
                                    <div className={"ms-1 d-flex"} title={"Проект для школьников"}>
                                        <Label theme={"warning"} icon={<Icon size={14} data={Shapes3}/>}/>
                                    </div>}
                            </div>}
                    </div>
                    <Link to={`/project/${project.id}`} className="project-card__title single-line-text mt-0"
                          target="_blank" rel="noopener noreferrer">
                        <h5 className={"mb-0"}>{project.name}</h5>
                    </Link>
                </> :
                <>
                    <div className={"d-flex align-items-center justify-content-between mb-1"}>
                        <div className="project-card__title single-line-text mt-0">
                            <h5 className={"mb-0"}>{project.name}</h5>
                        </div>
                        <div></div>
                    </div>
                </>
            }

            <div className={`${project.id < 0 ? "four-columns-container gap-1" : "distribution-participant-list"}`}>
                {project.participants.map((participant) => (
                    <ParticipantCard key={participant.id}
                                     participant={participant}
                                     currentProjectId={project.id}
                    />
                ))}
            </div>
        </div>
    );
};

function ReflectionReviewList({user}) {
    const findTemplateByType = (templates, type) => templates.find(template => template.type_file === type);

    const [isLoading, setIsLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const [projects, setProjects] = useState(null);

    const [templates, setTemplates] = useState({
        worker_reflection: null,
        worker_feedback: null,
        manager_reflection: null,
        manager_feedback: null
    });
    const [successIcons, setSuccessIcons] = useState({
        worker_reflection: false,
        worker_feedback: false,
        manager_reflection: false,
        manager_feedback: false
    });

    const [venues, setVenues] = useState([]);
    const [streams, setStreams] = useState([]);

    const [venueFilter, setVenueFilter] = useState([]);
    const [streamFilter, setStreamFilter] = useState([]);
    const [schoolchildrenFilter, setSchoolchildrenFilter] = useState('');

    useEffect(() => {
        axios
            .get(REFLECTIONS_TEMPLATES_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                const templates = response.data;
                const fetchedTemplates = response.data.reduce((acc, template) => {
                    acc[template.type_file] = template;
                    return acc;
                }, {});
                setTemplates(fetchedTemplates);
            })
            .catch(error => {
                console.error('Ошибка при получении шаблонов:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });

        axios
            .get(VENUES_ENDPOINT(), getJSONConfig())
            .then(response => {
                setVenues(response.data);
            });

        axios
            .get(STREAMS_ENDPOINT, getJSONConfig())
            .then(response => {
                // Сортируем массив так, чтобы элемент "Нераспределенные проекты" был первым
                response.data.sort((a, b) => {
                    if (a.name === UNALLOCATED_PROJECTS) {
                        return -1;
                    } else if (b.name === UNALLOCATED_PROJECTS) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                setStreams(response.data);
            });

        axios
            .get(FIX_DISTRIBUTION_ENDPOINT, getJSONAcceptAuthConfig())
            .then(response => {
                setProjects(response.data.projects);
                // setDeletedProjects(response.data.stop_projects);
                // if (response.status === 201) {
                //     let project_workers = response.data;
                //     let projects = [];
                //     project_workers.forEach(project_worker => {
                //         let project = projects.find(p => p === project_worker.project);
                //         if (!project) {
                //             projects.push(project_worker.project);
                //         }
                //     })
                //     setFixAnswerText("Зафиксировано успешно. Участников: " + project_workers.length + ", проектов: " + projects.length);
                // }
            })
            .catch(e => {
                // setFixAnswerText("Ошибка при фиксации распределения")
                console.error("Ошибка при получении распределения:", e);
            })
            .finally(() => {
                // setGettingGayleShapley(false);
            });
    }, []);

    const handleFileUpload = async (e, type) => {
        setSubmitting(true);

        const file = e.target.files[0];
        if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            const formData = new FormData();
            formData.append('workshop', '1');
            formData.append('file', file);
            formData.append('type_file', type);

            const templateExists = !!templates[type];

            axios({
                method: templateExists ? 'put' : 'post',
                url: templateExists ? REFLECTIONS_TEMPLATE_DETAIL_ENDPOINT(templates[type].id) : REFLECTIONS_TEMPLATES_ENDPOINT,
                data: formData,
                ...getAcceptAuthConfig()
            })
                .then(response => {
                    setTemplates(prevTemplates => ({
                        ...prevTemplates,
                        [type]: response.data
                    }));
                    setSuccessIcons(prevSuccessIcons => ({
                        ...prevSuccessIcons,
                        [type]: true
                    }));
                    setTimeout(() => {
                        setSuccessIcons(prevSuccessIcons => ({
                            ...prevSuccessIcons,
                            [type]: false
                        }));
                    }, 1500);
                })
                .catch(error => {
                    console.error('Ошибка при загрузке шаблона:', error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            setSubmitting(false);
            alert('Пожалуйста, выберите файл формата Word.');
        }
    };

    const handleDownloadTemplate = (template) => {
        if (template) {
            openFileInNewTab(null, template.file, template.type_file);
        } else {
            alert('Шаблон не загружен.');
        }
    };

    const filterProjects = () => {
        return projects?.filter(project => {
            if (project.id < 0) {
                return false;
            }
            // Фильтрация по площадке
            if (venueFilter.length > 0) {
                if (venueFilter.includes("-1")) {
                    if (project.venue && !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана и в фильтре выбрана опция "Не выбрана"
                    }
                } else {
                    if (!project.venue || !venueFilter.includes(project.venue.id.toString())) {
                        return false; // Если площадка проекта не выбрана или не содержится в списке фильтра по площадке
                    }
                }
            }

            // Фильтрация по потоку
            if (streamFilter.length > 0) {
                if (!project.stream || !streamFilter.includes(project.stream.toString())) {
                    return false;
                }
            }

            // Фильтрация по наличию или отсутствию школьных проектов
            if (schoolchildrenFilter.length > 0) {
                if (schoolchildrenFilter[0] === 'true' && !project.is_for_schoolchildren) {
                    return false;
                }
                if (schoolchildrenFilter[0] === 'false' && project.is_for_schoolchildren) {
                    return false;
                }
            }

            return true;
        });
    };

    const filteredProjects = filterProjects();

    return (
        <>
            <h3 className="mb-2">Рефлексии и обратная связь</h3>
            <hr className="clearfix w-100 pb-0"/>
            {isLoading &&
                <LoadingSpinner/> ||
                <>
                    {user && user.is_ws_director &&
                        <>
                            <div className="two-columns-container">
                                <div>
                                    <h5>Шаблоны для участников</h5>
                                    <div className="template-section">
                                        {successIcons.worker_reflection ?
                                            <Icon data={FileCheck} size={46} className={"success-icon"}/> :
                                            <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}
                                                                     size={46}
                                                                     onClick={() => handleDownloadTemplate(templates.worker_reflection)}
                                                                     disabled={!templates.worker_reflection}
                                            />}
                                        <Form.Group>
                                            <Form.Label>Шаблон рефлексии участника</Form.Label>
                                            <Form.Control type="file" size="sm"
                                                          accept={REFLECTION_FORMATS}
                                                          name="workerReflectionTemplate"
                                                          onChange={(e) => handleFileUpload(e, 'worker_reflection')}
                                                          disabled={submitting}/>
                                        </Form.Group>
                                    </div>
                                    {/*<div className="template-section">*/}
                                    {/*    {successIcons.worker_feedback ?*/}
                                    {/*        <Icon data={FileCheck} size={46} className={"success-icon"}/> :*/}
                                    {/*        <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}*/}
                                    {/*                                 size={46}*/}
                                    {/*                                 onClick={() => handleDownloadTemplate(templates.worker_feedback)}*/}
                                    {/*                                 disabled={!templates.worker_feedback}*/}
                                    {/*        />}*/}
                                    {/*    <Form.Group>*/}
                                    {/*        <Form.Label>Шаблон обратной связи участника</Form.Label>*/}
                                    {/*        <Form.Control type="file" size="sm"*/}
                                    {/*                      accept=".docx"*/}
                                    {/*                      name="workerReflectionTemplate"*/}
                                    {/*                      onChange={(e) => handleFileUpload(e, 'worker_feedback')}*/}
                                    {/*                      disabled={submitting}/>*/}
                                    {/*    </Form.Group>*/}
                                    {/*</div>*/}
                                </div>
                                <div>
                                    <h5>Шаблоны для кураторов</h5>
                                    <div className="template-section">
                                        {successIcons.manager_reflection ?
                                            <Icon data={FileCheck} size={46} className={"success-icon"}/> :
                                            <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}
                                                                     size={46}
                                                                     onClick={() => handleDownloadTemplate(templates.manager_reflection)}
                                                                     disabled={!templates.manager_reflection}
                                            />}
                                        <Form.Group>
                                            <Form.Label>Шаблон рефлексии куратора</Form.Label>
                                            <Form.Control type="file" size="sm"
                                                          accept={REFLECTION_FORMATS}
                                                          name="workerReflectionTemplate"
                                                          onChange={(e) => handleFileUpload(e, 'manager_reflection')}
                                                          disabled={submitting}/>
                                        </Form.Group>
                                    </div>
                                    {/*<div className="template-section">*/}
                                    {/*    {successIcons.manager_feedback ?*/}
                                    {/*        <Icon data={FileCheck} size={46} className={"success-icon"}/> :*/}
                                    {/*        <HoverableIconLikeButton icon={FileText} hoverIcon={FileArrowDown}*/}
                                    {/*                                 size={46}*/}
                                    {/*                                 onClick={() => handleDownloadTemplate(templates.manager_feedback)}*/}
                                    {/*                                 disabled={!templates.manager_feedback}*/}
                                    {/*        />}*/}
                                    {/*    <Form.Group>*/}
                                    {/*        <Form.Label>Шаблон обратной связи куратора</Form.Label>*/}
                                    {/*        <Form.Control type="file" size="sm"*/}
                                    {/*                      accept=".docx"*/}
                                    {/*                      name="workerReflectionTemplate"*/}
                                    {/*                      onChange={(e) => handleFileUpload(e, 'manager_feedback')}*/}
                                    {/*                      disabled={submitting}/>*/}
                                    {/*    </Form.Group>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </>
                    }
                    <div className="project-filters wide-filters-label mt-4">
                        <div className="filters-column">
                            <div className="filter">
                                <label htmlFor="venueFilter">Площадка:</label>
                                <Select id="venueFilter"
                                        multiple={true}
                                        hasClear
                                        placeholder={'Все'}
                                        onUpdate={(values) => setVenueFilter(values)}>
                                    <Select.Option value={"-1"}>Не выбрана</Select.Option>
                                    {venues.map((venue) => (<Select.Option key={venue.id}
                                                                           value={venue.id.toString()}>{venue.name}</Select.Option>))}
                                </Select>
                            </div>

                            <div className="filter">
                                <label htmlFor="streamFilter">Поток:</label>
                                <Select id="streamFilter"
                                        multiple={true}
                                        hasClear
                                        placeholder={'Все'}
                                        onUpdate={(values) => setStreamFilter(values)}>
                                    {streams.map((stream) => (<Select.Option key={stream.id}
                                                                             value={stream.id.toString()}>{stream.name}</Select.Option>))}
                                </Select>
                            </div>
                        </div>

                        <div className="filters-column wide-filters-label narrow-filters-select">
                            <div className="filter">
                                <label htmlFor="schoolchildrenFilter">Для школьников:</label>
                                <Select id="schoolchildrenFilter"
                                        hasClear
                                        placeholder={'Все'}
                                        onUpdate={(values) => setSchoolchildrenFilter(values)}>
                                    <Select.Option value="true">Да</Select.Option>
                                    <Select.Option value="false">Нет</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className="projects__quantity-info quantity-info">
                        Кол-во проектов: {filteredProjects?.length || 0}
                    </div>

                    <div className="four-columns-container mt-3">
                        {projects && filteredProjects.map((project, index) => (
                            <Project key={project.id} className={"columns-item"}
                                     project={project}/>
                        ))}
                    </div>
                </>
            }
        </>
    );
}

const mapStateToProps = state => ({
    user: state.auth.user,
});
export default connect(mapStateToProps)(ReflectionReviewList);