import React, {useEffect, useState} from 'react'
import {Button, Icon, Popover} from '@gravity-ui/uikit';
import axios from "axios";

import {
    ACTIVE_WORKSHOP_ABOUT_ENDPOINT, getAcceptAuthConfig, WORKSHOP_ABOUT_ENDPOINT,
} from '../../api/api'
import BMMMarkdown from "../accountPage/Director/BMMMarkdown";
import {EditSlash} from "../../components/Icons/Icons";
import {EditButtonIcon} from "../../components/Button/Button";
import {connect} from "react-redux";
import WorkshopAboutForm from "../accountPage/Director/WorkshopAboutForm";

const AboutPage = ({user}) => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(require.context('../../../public/static/aboutImg', false, /\.(png|jpg|svg|webp)$/));
    const [markdown_2, setMarkdown_2] = useState('');
    const [editMarkdown_2, setEditMarkdown_2] = useState('');

    const [isEditing, setIsEditing] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);

    async function getMarkdown() {
        try {
            const response = await axios.get(ACTIVE_WORKSHOP_ABOUT_ENDPOINT)
            setMarkdown_2(response.data['md_text'])
            setEditMarkdown_2(response.data['md_text'])
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIsNotFound(true); // Устанавливаем состояние 404
            } else {
                console.error('Не удалось получить описание мастерской:', error);
            }
        }
    }

    useEffect(() => {
        getMarkdown();
    }, []);

    const onChange = (e) => {
        setEditMarkdown_2(e.target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        const requestMethod = isNotFound ? 'post' : 'put'; // Выбираем метод запроса в зависимости от состояния

        axios({
            method: requestMethod,
            url: ACTIVE_WORKSHOP_ABOUT_ENDPOINT,
            data: {md_text: editMarkdown_2},
            ...getAcceptAuthConfig()
        })
            .then(response => {
                setMarkdown_2(response.data.md_text);
                setEditMarkdown_2(response.data.md_text);
                setIsEditing(false);
                window.location.reload(); // Reload the page after saving
            })
            .catch(error => {
                console.error('Не удалось сохранить этапы:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            {user && user.is_ws_director && (
                <div className={"d-flex justify-content-end align-items-center"}>
                    {isEditing ? (
                        <Button view={"normal"} size={"m"} onClick={() => setIsEditing(false)}
                                title={"Отменить редактирование"}>
                            <Icon data={EditSlash} size={18}/>
                        </Button>
                    ) : (
                        <EditButtonIcon onClick={() => setIsEditing(true)}/>
                    )}
                </div>
            )}
            {isEditing ? (
                <WorkshopAboutForm about={editMarkdown_2} onChange={onChange} submitting={submitting}
                                   onSubmit={onSubmit}/>
            ) : (
                <BMMMarkdown markdown_2={markdown_2} images={images}/>
            )}
        </>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(AboutPage);