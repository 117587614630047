import {jwtDecode} from 'jwt-decode';
import {refresh_token} from '../actions/auth'

const API_URL = process.env.REACT_APP_API_URL;
export const USER_APPLICATION_ENDPOINT = `${API_URL}/api/project_distribution/project_user_cv/`;
export const USER_APPLICATIONS_ENDPOINT = `${API_URL}/api/project_distribution/project_user_cvs/`;
export const USER_APPLICATION_LOCAL_WORKSHOPS_ENDPOINT = `${API_URL}/api/project_distribution/user_local_workshops/`;
export const USER_APPLICATION_DETAIL_ENDPOINT = (applicationId) => `${API_URL}/api/project_distribution/project_user_cv/${applicationId}/`;
export const USER_APPLICATIONS_DETAIL_ENDPOINT = (applicationId) => `${API_URL}/api/project_distribution/project_user_cvs/${applicationId}/`;
export const CONFIRMATION_DETAIL_ENDPOINT = (id) => `${API_URL}/api/cv/confirmation/${id}/`;
export const PROJECT_CHARACTERISTIC_DETAIL_ENDPOINT = (id) =>  `${API_URL}/api/project_distribution/proj_characteristic/${id}/`;
export const PROJECT_CHARACTERISTIC_ENDPOINT = `${API_URL}/api/project_distribution/proj_characteristic/`;
export const ALL_TRAVEL_XLSX_ENDPOINT =  `${API_URL}/api/travel_grant/travel_grants_excel`;
export const PROJECT_APPLICATIONS_DIRECTOR_ENDPOINT = `${API_URL}/api/project_distribution/director/`;
export const PROJECT_DISTRIBUTION_MANAGER_ENDPOINT = `${API_URL}/api/project_distribution/manager/`;
export const PROJECT_DISTRIBUTION_MANAGER_DETAIL_ENDPOINT = (applicationId) => `${API_URL}/api/project_distribution/manager/${applicationId}/`;
export const USER_TRAVEL_GRANTS_ENDPOINT = `${API_URL}/api/travel_grant/user_travel_grants/`;
export const ALL_TRAVEL_GRANTS_ENDPOINT = `${API_URL}/api/travel_grant/travel_grants/`;
export const TRAVEL_GRANTS_REVIEWER_COMMENT_ENDPOINT = (id) => `${API_URL}/api/travel_grant/reviewer_comment_${id}/`;
export const UPDATE_LIVING_ENDPOINT = (id) => `${API_URL}/api/travel_grant/livings/${id}/`;
export const USER_TRAVEL_GRANTS_DETAIL_ENDPOINT = (travelGrantId) => `${API_URL}/api/travel_grant/user_travel_grants/${travelGrantId}/`;
export const PROJECTS_ENDPOINT = `${API_URL}/api/projects/projects/`;
export const PUBLIC_PROJECTS_ENDPOINT = (isActive = 'True') => `${API_URL}/api/projects/public_projects/?is_active=${isActive}`;
export const PUBLIC_PROJECTS_DETAIL_ENDPOINT = (projectId) => `${API_URL}/api/projects/public_projects/${projectId}/`;
export const REVIEW_PROJECTS_ENDPOINT = `${API_URL}/api/projects/review_projects/`;
export const PROJECT_DETAIL_ENDPOINT = (projectId) => `${API_URL}/api/projects/projects/${projectId}/`;
export const PROJECT_SEARCH_ENDPOINT = (value) => `${API_URL}/api/projects/project-search?partial_name=${value}/`;
export const REVIEW_PROJECT_DETAIL_ENDPOINT = (projectId) => `${API_URL}/api/projects/review_projects/${projectId}/`;
export const USER_PROJECTS_ENDPOINT = `${API_URL}/api/projects/user_projects/`;
export const MANAGER_PROJECTS_ENDPOINT = `${API_URL}/api/projects/manager_projects/`;
export const PARTICIPANT_PROJECTS_ENDPOINT = `${API_URL}/api/projects/participant_projects/`;
export const USER_SEARCH_ENDPOINT = (value) => `${API_URL}/api/auth/user-search?partial_name=${value}/`;
export const GAYLE_SHAPLEY_ENDPOINT = `${API_URL}/api/project_distribution/gayle_shapley/`;
export const VENUES_ENDPOINT = (isActive = 'Any') => `${API_URL}/api/workshops/venues/?is_active=${isActive}`;
export const VENUE_DETAIL_ENDPOINT = (venueId) => `${API_URL}/api/workshops/venues/${venueId}/`;
export const LOCAL_WORKSHOP_ENDPOINT = `${API_URL}/api/workshops/local_workshops/`;
export const LOCAL_WORKSHOP_DETAIL_ENDPOINT = (localWorkshopId) => `${API_URL}/api/workshops/local_workshops/${localWorkshopId}/`;
export const USER_LOCAL_WORKSHOP_ENDPOINT = `${API_URL}/api/workshops/user_local_workshops/`;
export const FIX_DISTRIBUTION_ENDPOINT = `${API_URL}/api/projects/workers/`;
export const STREAMS_ENDPOINT = `${API_URL}/api/streams/streams/`;
export const STREAM_DETAIL_ENDPOINT = (streamId) => `${API_URL}/api/streams/streams/${streamId}/`;
export const LOCAL_STREAM_ENDPOINT = `${API_URL}/api/streams/local_streams/`;
export const LOCAL_STREAM_DETAIL_ENDPOINT = (localStreamId) => `${API_URL}/api/streams/local_streams/${localStreamId}/`;
export const USER_LOCAL_STREAM_ENDPOINT = `${API_URL}/api/streams/user_local_streams/`;
export const KEYWORDS_ENDPOINT = `${API_URL}/api/projects/keywords/`;
export const REFLECTIONS_TEMPLATES_ENDPOINT = `${API_URL}/api/reflection/templates/`;
export const REFLECTIONS_TEMPLATE_DETAIL_ENDPOINT = (templateId) => `${API_URL}/api/reflection/templates/${templateId}/`;
export const REFLECTIONS_MANAGER_ENDPOINT = `${API_URL}/api/reflection/manager/`;
export const REFLECTIONS_MANAGER_DETAIL_ENDPOINT = (fileId) => `${API_URL}/api/reflection/manager/${fileId}/`;
export const REFLECTIONS_WORKER_ENDPOINT = `${API_URL}/api/reflection/worker/`;
export const REFLECTIONS_WORKER_DETAIL_ENDPOINT = (fileId) => `${API_URL}/api/reflection/worker/${fileId}/`;
export const REFLECTIONS_REVIEW_ENDPOINT = `${API_URL}/api/reflection/review/`;
export const REFLECTIONS_DOWNLOAD_ENDPOINT = `${API_URL}/api/reflection/bulk-reflections-download/`;
export const GRAYLIST_ENDPOINT = (userId) => `${API_URL}/api/gray-list/${userId}/`;
export const GRAYLIST_DETAIL_ENDPOINT = (userId, messageId) => `${API_URL}/api/gray-list/${userId}/${messageId}/`;

export const USER_PERMISSIONS_ENDPOINT = (userId) => `${API_URL}/api/permissions/user-permission/${userId}/`;
export const ACTION_ENDPOINT = `${API_URL}/api/permissions/action/`;
export const WORKSHOP_PERMISSIONS = `${API_URL}/api/permissions/workshop-permission/`;
export const WORKSHOP_PERMISSIONS_DETAIL = (permissionId) => `${API_URL}/api/permissions/workshop-permission/${permissionId}/`;

export const STAGE_ENDPOINT = (workshopId) => `${API_URL}/api/stages/${workshopId}/`
export const ACTIVE_STAGE_ENDPOINT = `${API_URL}/api/stages/`

export const ACTIVE_WORKSHOP_ABOUT_ENDPOINT = `${API_URL}/api/workshops/about/`
export const WORKSHOP_ABOUT_ENDPOINT = (workshopId) => `${API_URL}/api/workshops/about/${workshopId}/`

// export const WORKSHOP_DIRECTOR_ENDPOINT = `${API_URL}/api/workshops/director/`

export const WORKSHOPS_INFO_ENDPOINT = `${API_URL}/api/workshops/info/`
export const WORKSHOP_INFO_ENDPOINT = (workshopId) => `${API_URL}/api/workshops/info/${workshopId}/`


export const JWT_CREATE_ENDPOINT = `${API_URL}/api/auth/jwt/create/`;
export const JWT_REFRESH_ENDPOINT = `${API_URL}/api/auth/jwt/refresh/`;
export const JWT_VERIFY_ENDPOINT = `${API_URL}/api/auth/jwt/verify/`;

export const ALL_USERS_ENDPOINT = `${API_URL}/api/auth/all_users/`;
export const USER_DETAIL_ENDPOINT = (userId) => `${API_URL}/api/auth/all_users/${userId}/`;
export const AUTH_SIGNUP_ENDPOINT = `${API_URL}/api/auth/users/`;
export const AUTH_ACTIVATION_ENDPOINT = `${API_URL}/api/auth/users/activation/`;
export const AUTH_RESEND_ACTIVATION_ENDPOINT = `${API_URL}/api/auth/users/resend_activation/`;
export const AUTH_RESET_PASSWORD_ENDPOINT = `${API_URL}/api/auth/users/reset_password/`;
export const AUTH_RESET_PASSWORD_CONFIRM_ENDPOINT = `${API_URL}/api/auth/users/reset_password_confirm/`;
export const AUTH_LOAD_CURRENT_USER_ENDPOINT = `${API_URL}/api/auth/users/me/`;

function token_needs_refresh() {
    try {
        const decodedToken = jwtDecode(localStorage.getItem('access'));
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp <= currentTime) {
            return true;
        }
    } catch (error) {
        console.error("Ошибка при декодировании JWT:", error);
        return false;
    }
    return false;
}


export const getJSONAcceptAuthConfig = () => {
    if (token_needs_refresh()) {
        refresh_token();
    }
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
}

export const getAcceptAuthConfig = () => {
    if (token_needs_refresh()) {
        refresh_token();
    }
    return {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
        }
    };
}


export const getJSONAcceptConfig = () => {
    return {
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/json'
        }
    };
}

export const getJSONConfig = () => {
    return {
        headers: {
            'Content-Type': 'application/json'
        }
    };
}