import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Button, Icon} from "@gravity-ui/uikit";
import {Plus} from "@gravity-ui/icons";
import {Accordion, Form} from "react-bootstrap";
import Select from "react-select";

import {DeleteModal, UpdateModal} from "../../components/Modal/Modals";

import {
    getAcceptAuthConfig, LOCAL_STREAM_DETAIL_ENDPOINT, LOCAL_STREAM_ENDPOINT,
    STREAM_DETAIL_ENDPOINT,
    STREAMS_ENDPOINT, USER_LOCAL_STREAM_ENDPOINT,
} from "../../api/api";
import {AddButtonMaxWidth, DeleteButtonIcon, EditButtonIcon} from "../../components/Button/Button";
import {ProjectPersona} from "../../components/projectCard/utils";
import {AsyncSelectField} from "./ProjectFields";
import {getDefaultActiveKey, onAccordionSelect} from "../../utils/accordionUtils";
import {UNALLOCATED_PROJECTS, UNDEFINED_STREAM, UNDEFINED_VENUE} from "../../utils/const";

const StreamForm = ({submitting, onSubmit, streamData, setStreamData, errors, setOpen}) => {
    const onChange = (e) => {
        const {name, value} = e.target;
        setStreamData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group controlId="formStreamName">
                <Form.Label className="required">Название потока</Form.Label>
                <Form.Control required
                              disabled={streamData.name === UNALLOCATED_PROJECTS}
                              type="text"
                              name="name"
                              value={streamData.name}
                              onChange={onChange}
                              autoComplete="off"
                              isInvalid={!!(errors && errors.name)}/>
                <Form.Control.Feedback type="invalid">{errors && errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formStreamDescription">
                <Form.Label>Описание</Form.Label>
                <Form.Control as="textarea"
                              name="description"
                              value={streamData.description}
                              onChange={onChange}
                              isInvalid={!!(errors && errors.description)}/>
                <Form.Control.Feedback type="invalid">{errors && errors.description}</Form.Control.Feedback>
            </Form.Group>
            <div className="line-btn-group">
                <Button view="normal" onClick={() => setOpen(false)} width="max">Отменить</Button>
                <Button view="action" type="submit" disabled={submitting} width="max">
                    {submitting ? "Сохранение..." : "Сохранить"}
                </Button>
            </div>
        </Form>
    );
};

const StreamFormUpdate = ({stream, setOpenUpdate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [streamData, setStreamData] = useState(stream);
    const [errors, setErrors] = useState({
        name: "",
        description: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .patch(STREAM_DETAIL_ENDPOINT(stream.id), streamData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error updating stream data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <StreamForm submitting={submitting} onSubmit={onSubmit}
                    streamData={streamData} setStreamData={setStreamData}
                    errors={errors}
                    setOpen={setOpenUpdate}/>
    );
};

const StreamFormCreate = ({setOpenCreate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [streamData, setStreamData] = useState({
        name: "",
        description: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        description: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .post(STREAMS_ENDPOINT, streamData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error updating stream data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <StreamForm submitting={submitting} onSubmit={onSubmit}
                    streamData={streamData} setStreamData={setStreamData}
                    errors={errors}
                    setOpen={setOpenCreate}/>
    );
};

const Stream = ({stream}) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);

    const onDelete = async (streamId) => {
        try {
            await axios.delete(STREAM_DETAIL_ENDPOINT(streamId), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении потока:', error);
        }
    };

    return (
        <li className="bmm-card">
            <div className="d-flex justify-content-between align-items-center">
                <div className="bmm-text_bold">{stream.name}</div>
                <div className="line-btn-group">
                    {stream.name !== UNALLOCATED_PROJECTS && <DeleteButtonIcon onClick={() => setOpenDelete(true)}/>}
                    <EditButtonIcon onClick={() => setOpenUpdate(true)}/>
                </div>
                <DeleteModal type="поток" name={`${stream.name}`}
                             onDelete={() => onDelete(stream.id)}
                             open={openDelete} setOpen={setOpenDelete}/>
                <UpdateModal open={openUpdate} setOpen={setOpenUpdate}>
                    <StreamFormUpdate stream={stream} setOpenUpdate={setOpenUpdate}/>
                </UpdateModal>
            </div>
            {stream.description && <div className="bmm-text_bold">
                <strong>Описание:</strong> {stream.description}
            </div>}
        </li>
    );
};

const LocalStreamForm = ({
                             submitting,
                             onSubmit,
                             localWorkshopName,
                             localStreamData,
                             setLocalStreamData,
                             errors,
                             setOpen
                         }) => {
    const [streams, setStreams] = useState([]);

    useEffect(() => {
        axios
            .get(STREAMS_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                const newStreams = [...response.data.map((stream) => ({
                    label: `${stream.name}`, value: stream.id.toString()
                })),];
                setStreams(newStreams);
            })
            .catch(error => {
                console.error('Error getting streams data:', error);
            });
    }, []);

    const onChange = (e, projectDataField) => {
        const value = !e ? "" : e.value;
        setLocalStreamData((prevData) => ({
            ...prevData,
            [projectDataField]: value,
        }));
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group controlId="Workshop">
                <Form.Label className="required">Площадка</Form.Label>
                <Form.Control required
                              disabled
                              type="text"
                              name="workshop"
                              value={localWorkshopName}/>
            </Form.Group>
            <Form.Group controlId="formStream">
                <Form.Label className="required">Поток</Form.Label>
                <Select required
                        name="stream"
                        value={localStreamData.stream?.id
                            ? streams.find((stream) => stream.value === localStreamData.stream.id.toString())
                            : localStreamData.stream
                                ? streams.find((stream) => stream.value === localStreamData.stream)
                                : null}
                        onChange={(e) => onChange(e, 'stream')}
                        options={streams}
                        noOptionsMessage={() => 'Поток не найден'}
                        components={{IndicatorSeparator: null}}
                        classNamePrefix="bmm-select"
                        placeholder={null}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.stream}</Form.Control.Feedback>
            </Form.Group>
            <AsyncSelectField required={false}
                              controlId="formLocalStreamManager"
                              label="Руководитель потока"
                              name="manager"
                              value={localStreamData.manager?.email
                                  ? [{
                                      label: localStreamData.manager.email,
                                      value: localStreamData.manager.email
                                  }]
                                  : localStreamData.manager
                                      ? [{
                                          label: localStreamData.manager,
                                          value: localStreamData.manager
                                      }]
                                      : null}
                              onChange={onChange}
                              errors={errors}
            />
            <div className="line-btn-group">
                <Button view="normal" onClick={() => setOpen(false)} width="max">Отменить</Button>
                <Button view="action" type="submit" disabled={submitting} width="max">
                    {submitting ? "Сохранение..." : "Сохранить"}
                </Button>
            </div>
        </Form>
    );
};

const LocalStreamFormUpdate = ({id, localWorkshopName, stream, manager, setOpenUpdate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [localStreamData, setLocalStreamData] = useState({
        stream: stream?.id.toString(),
        manager: manager || "",
    });
    const [errors, setErrors] = useState({
        stream: "",
        manager: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .patch(LOCAL_STREAM_DETAIL_ENDPOINT(id), localStreamData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error creating localWorkshop data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <LocalStreamForm submitting={submitting} onSubmit={onSubmit}
                         localWorkshopName={localWorkshopName}
                         localStreamData={localStreamData} setLocalStreamData={setLocalStreamData}
                         errors={errors}
                         setOpen={setOpenUpdate}/>
    );
};

const LocalStreamFormCreate = ({localWorkshopId, localWorkshopName, setOpenCreate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [localStreamData, setLocalStreamData] = useState({
        stream: "",
        manager: "",
        local_workshop: localWorkshopId,
    });
    const [errors, setErrors] = useState({
        stream: "",
        manager: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .post(LOCAL_STREAM_ENDPOINT, localStreamData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error creating localWorkshop data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <LocalStreamForm submitting={submitting} onSubmit={onSubmit}
                         localWorkshopName={localWorkshopName}
                         localStreamData={localStreamData} setLocalStreamData={setLocalStreamData}
                         errors={errors}
                         setOpen={setOpenCreate}/>
    );
};

const LocalStream = ({localWorkshopName, localStream}) => {
    const {id, stream, managers} = localStream;

    const [openDelete, setOpenDelete] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);

    const onDelete = async (localWorkshopId) => {
        try {
            await axios.delete(LOCAL_STREAM_DETAIL_ENDPOINT(localWorkshopId), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            console.error('Ошибка при удалении площадки из мастерской:', error);
        }
    };

    return (
        <li className="local-workshop-item">
            <div className="d-flex justify-content-between align-items-center">
                <div className="bmm-text_bold">{stream?.name || UNDEFINED_STREAM}</div>
                <div className="line-btn-group">
                    {stream?.name !== UNALLOCATED_PROJECTS && <DeleteButtonIcon onClick={() => setOpenDelete(true)}/>}
                    <EditButtonIcon onClick={() => setOpenUpdate(true)}/>
                </div>

                <DeleteModal type="поток" name={stream?.name}
                             onDelete={() => onDelete(id)}
                             open={openDelete} setOpen={setOpenDelete}
                             additionalText={`с площадки ${localWorkshopName}`}/>
                <UpdateModal open={openUpdate} setOpen={setOpenUpdate}>
                    <LocalStreamFormUpdate id={id}
                                           localWorkshopName={localWorkshopName}
                                           stream={stream} manager={managers[0]}
                                           setOpenUpdate={setOpenUpdate}/>
                </UpdateModal>
            </div>
            {managers.map((manager) => (
                <ProjectPersona key={manager.id} user={manager}/>
            ))}
        </li>
    );
};

const LocalWorkshop = ({workshopName, localWorkshopId, venue, localStreams}) => {
    const localWorkshopName = venue
        ? [workshopName, venue.name, venue.city].filter(Boolean).join(", ")
        : [workshopName, UNDEFINED_VENUE].join(", ")

    const [openCreate, setOpenCreate] = useState(false);

    return (
        <Accordion.Item eventKey={`localWorkshopNoS-${localWorkshopId}`}>
            <Accordion.Header>
                <h6 className="m-0">
                    {venue
                        ? `${venue.name}, ${venue.city}, ${venue.address}`
                        : <div className="bmm-text_gray">{UNDEFINED_VENUE}</div>}
                </h6>
            </Accordion.Header>
            <Accordion.Body className="local-workshop-list">
                <AddButtonMaxWidth className={"mb-3"} text={"Добавить поток на площадку"}
                                   onClick={() => setOpenCreate(true)}/>
                <UpdateModal open={openCreate} setOpen={setOpenCreate}>
                    <LocalStreamFormCreate localWorkshopId={localWorkshopId} localWorkshopName={localWorkshopName}
                                           setOpenCreate={setOpenCreate}/>
                </UpdateModal>
                <ul className="list-unstyled mb-0">
                    {localStreams && localStreams.map((localStream) => (
                        <LocalStream key={localStream.id}
                                     localWorkshopName={localWorkshopName}
                                     localStream={localStream}/>
                    ))}
                </ul>
            </Accordion.Body>
        </Accordion.Item>
    );
};

const Workshop = ({workshopId, workshopName, localWorkshops}) => {
    return (
        <Accordion.Item eventKey={`workshopNoS-${workshopId}`}>
            <Accordion.Header>
                <h5 className="m-0">{workshopName}</h5>
            </Accordion.Header>
            <Accordion.Body className="local-workshop-list">
                <ul className="list-unstyled mb-0">
                    <Accordion className="about-accordion"
                               alwaysOpen
                               defaultActiveKey={getDefaultActiveKey(`expandedLocalWorkshopNoS-${workshopId}`)}
                               onSelect={(e) => onAccordionSelect(e, `expandedLocalWorkshopNoS-${workshopId}`)}>
                        {localWorkshops.map(({id, venue, local_streams}) => (
                            <LocalWorkshop key={id}
                                           workshopId={workshopId}
                                           workshopName={workshopName}
                                           localWorkshopId={id} venue={venue}
                                           localStreams={local_streams}/>
                        ))}
                    </Accordion>
                </ul>
            </Accordion.Body>
        </Accordion.Item>
    );
};

function StreamReviewList() {
    const [streams, setStreams] = useState([]);
    const [openCreate, setOpenCreate] = useState(false);
    const [workshops, setWorkshops] = useState([]);

    useEffect(() => {
        axios
            .get(STREAMS_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                response.data?.sort((a, b) => {
                    if (a.name === UNALLOCATED_PROJECTS && b.name !== UNALLOCATED_PROJECTS) {
                        return 1;
                    } else if (a.name !== UNALLOCATED_PROJECTS && b.name === UNALLOCATED_PROJECTS) {
                        return -1;
                    } else {
                        return a.name.localeCompare(b.name);
                    }
                });
                setStreams(response.data);
            })
            .catch(error => {
                console.error('Error getting streams data:', error);
            });

        axios
            .get(USER_LOCAL_STREAM_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                response.data?.map(item => {
                    const sortedLocalWorkshops = item.local_workshops?.sort((a, b) => a.venue?.name.localeCompare(b.venue?.name));

                    const sortedLocalStreams = sortedLocalWorkshops?.map(workshop => ({
                        ...workshop,
                        local_streams: workshop?.local_streams?.sort((a, b) => {
                            if (a.stream?.name === UNALLOCATED_PROJECTS && b.stream?.name !== UNALLOCATED_PROJECTS) {
                                return 1;
                            } else if (a.stream?.name !== UNALLOCATED_PROJECTS && b.stream?.name === UNALLOCATED_PROJECTS) {
                                return -1;
                            } else {
                                return a.stream?.name.localeCompare(b.stream?.name);
                            }
                        })
                    }));

                    return {
                        ...item,
                        local_workshops: sortedLocalWorkshops,
                        local_streams: sortedLocalStreams
                    };
                });
                setWorkshops(response.data);
            })
            .catch(error => {
                console.error('Error getting local_workshops data:', error);
            });
    }, []);

    const [expandedWorkshopNoS, setExpandedWorkshopNoS] = useState(() => (
        localStorage.getItem('expandedWorkshopNoS')
            ? JSON.parse(localStorage.getItem('expandedWorkshopNoS')) : ""));

    return (
        <>
            <h3 className="mb-2">Потоки на площадках</h3>
            <hr className="clearfix w-100 pb-0"/>
            <Accordion className="about-accordion"
                       alwaysOpen
                       defaultActiveKey={getDefaultActiveKey("expandedWorkshopNoS")}
                       onSelect={(e) => onAccordionSelect(e, "expandedWorkshopNoS")}>
                {workshops.map(({id, name, year, local_workshops}) => (
                    <Workshop key={id} workshopId={id} workshopName={`${name} ${year}`} localWorkshops={local_workshops}/>
                ))}
            </Accordion>

            <h3 className="mt-5 mb-2">Потоки</h3>
            <hr className="clearfix w-100 pb-0"/>
            <AddButtonMaxWidth text={"Добавить поток"} onClick={() => setOpenCreate(true)}/>
            <UpdateModal open={openCreate} setOpen={setOpenCreate}>
                <StreamFormCreate setOpenCreate={setOpenCreate}/>
            </UpdateModal>
            <ul className="list-unstyled mb-0">
                {streams.map((stream) => (
                    <Stream key={stream.id} stream={stream}/>
                ))}
            </ul>
        </>
    );
}

export default StreamReviewList;