import React from 'react';
import PropTypes from 'prop-types';
import './userCard.css';

const defaultImageURL = "https://img.freepik.com/free-psd/3d-illustration-bald-person-with-glasses_23-2149436184.jpg?w=2000&t=st=1706116706~exp=1706117306~hmac=5912213c4314755aaeae4c43629211b65948e2b570802c4a0c936e33538e6d58";

const getInitials = (name) => {
    if (!name || !name.trim()) return '';
    const names = name.trim().split(' ');
    const initials = names.map(n => n[0]?.toUpperCase()).join('');
    return initials.slice(0, 2); // Возвращаем только первые 2 буквы
};

const UserCard = ({name, email, text = '', imgUrl, size = 'm', side = 'right', className, initialsOnly = false}) => {
    const cardSizeClass = `user-card-${size}`;
    const sideClass = `user-card-${side}`;

    return (
        <div className={`user-card ${cardSizeClass} ${sideClass} ${className ? `${className}` : ''}`}>
            <div className="user-card__title">

                <div className="user-card__photo">
                    {imgUrl &&
                        <img src={imgUrl} alt="" className="user-photo"/> ||
                        <div className="user-photo-placeholder">
                            {getInitials(name)}
                        </div>}
                    {/*<img src={imgUrl || defaultImageURL} alt="" className="user-photo"/>*/}
                </div>

                {!initialsOnly && (name || email) && (<div className="user-details">
                    {name && <div className="user-name">{name}</div>}
                    {email && <div className="user-email">{email}</div>}
                </div>)}
            </div>
            {text && size === 'sp' && <div className="user-card__text mt-2">{text}</div>}
        </div>);
}

UserCard.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    text: PropTypes.string,
    imgUrl: PropTypes.string,
    size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'sp']),
    side: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
};

export default UserCard;
