import React, {useEffect, useState} from 'react';
import axios from "axios";

import {
    getAcceptAuthConfig, getJSONConfig,
    MANAGER_PROJECTS_ENDPOINT, REFLECTIONS_MANAGER_ENDPOINT, WORKSHOPS_INFO_ENDPOINT
} from "../../../api/api";
import ProjectManagerCard from "../../../components/projectCard/ProjectManagerCard";
import {getDefaultActiveKey, onAccordionSelect} from "../../../utils/accordionUtils";
import {Accordion} from "react-bootstrap";

function ProjectManagerList() {
    const [projects, setProjects] = useState([]);
    const [activeProjects, setActiveProjects] = useState([]);
    const [notActiveProjects, setNotActiveProjects] = useState([]);

    const [templates, setTemplates] = useState(null);
    const [userFiles, setUserFiles] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        axios
            .get(MANAGER_PROJECTS_ENDPOINT, {
                params: {is_active: "True"},
                headers: getAcceptAuthConfig().headers,
            })
            .then(response => {
                setActiveProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting my projects:', error);
            });

        axios
            .get(MANAGER_PROJECTS_ENDPOINT, {
                params: {is_active: "False"},
                headers: getAcceptAuthConfig().headers,
            })
            .then(response => {
                setNotActiveProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting my projects:', error);
            });

        axios
            .get(REFLECTIONS_MANAGER_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                const templates = response.data.templates;
                const filteredTemplates = templates.filter(template => template.type_file.startsWith('manager_'));
                setTemplates(filteredTemplates);
                setUserFiles(response.data.user_files);
            })
            .catch(error => {
                console.error('Ошибка при получении шаблонов и файлов:', error);
            });
    }, []);

    return (
        <>
            <h3 className="mb-2">Мои проекты</h3>
            <hr className="clearfix w-100 pb-0"/>

            {activeProjects.map((project) => (
                <ProjectManagerCard key={project.id} project={project}
                                    templates={templates} userFiles={userFiles} setUserFiles={setUserFiles}
                                    submitting={submitting} setSubmitting={setSubmitting}/>))}

            {notActiveProjects.length > 0 &&
                <Accordion className="about-accordion" alwaysOpen>
                    <Accordion.Item eventKey={`archive`}>
                        <Accordion.Header>
                            <h4 className="m-0">Архив</h4>
                        </Accordion.Header>
                        <Accordion.Body className="local-workshop-list">
                            {notActiveProjects.map((project) => (
                                <ProjectManagerCard key={project.id} project={project}
                                                    templates={templates} userFiles={userFiles}
                                                    setUserFiles={setUserFiles}
                                                    submitting={submitting} setSubmitting={setSubmitting}
                                                    with_bmm_name/>))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }
        </>
    );
}

export default ProjectManagerList;