import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    LOGOUT
} from './types';
import {
    getJSONConfig,
    getJSONAcceptConfig,
    getJSONAcceptAuthConfig,
    JWT_REFRESH_ENDPOINT,
    JWT_VERIFY_ENDPOINT,
    JWT_CREATE_ENDPOINT,
    AUTH_SIGNUP_ENDPOINT,
    AUTH_ACTIVATION_ENDPOINT,
    AUTH_RESET_PASSWORD_ENDPOINT,
    AUTH_RESET_PASSWORD_CONFIRM_ENDPOINT,
    AUTH_LOAD_CURRENT_USER_ENDPOINT, AUTH_RESEND_ACTIVATION_ENDPOINT
} from '../api/api'
import {jwtDecode} from "jwt-decode";

const BAD_CODES = ['token_not_valid', 'err_bad_request'];

function is_bad_code(code) {
    return !!BAD_CODES.includes(code?.toLowerCase());
}

export const load_user = () => async dispatch => {
    if (!localStorage.getItem('access')) {
        dispatch({
            type: USER_LOADED_FAIL
        });
        return;
    }

    await axios.get(AUTH_LOAD_CURRENT_USER_ENDPOINT, getJSONAcceptAuthConfig())
        .then(response => {
            dispatch({
                type: USER_LOADED_SUCCESS, payload: response.data
            });

            const decodedToken = jwtDecode(localStorage.getItem('access'));
            const timeoutDuration = decodedToken.exp * 1000 - Date.now() - 60000;
            setTimeout(() => dispatch(refresh_token()), timeoutDuration);
        })
        .catch(() => {
            dispatch({
                type: USER_LOADED_FAIL
            });
        });
};

export const refresh_token = () => async dispatch => {
    if (!localStorage.getItem('refresh')) {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
        return;
    }

    const body = JSON.stringify({refresh: localStorage.getItem('refresh')});
    await axios.post(JWT_REFRESH_ENDPOINT, body, getJSONAcceptConfig())
        .then(response => {
            if (!is_bad_code(response.data.code)) {
                dispatch({
                    type: LOGIN_SUCCESS, payload: response.data
                });
                dispatch(load_user());
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        })
        .catch(() => {
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        });
};

export const checkAuthenticated = () => async dispatch => {
    if (!localStorage.getItem('access')) {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
        return;
    }

    const body = JSON.stringify({token: localStorage.getItem('access')});
    await axios.post(JWT_VERIFY_ENDPOINT, body, getJSONAcceptConfig())
        .then(response => {
            if (!is_bad_code(response.data.code)) {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
                const decodedToken = jwtDecode(localStorage.getItem('access'));
                setTimeout(refresh_token, decodedToken.exp * 1000 - Date.now() - 60000);
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        })
        .catch(() => {
            dispatch(refresh_token())
        });
};

export const login = (email, password) => async dispatch => {
    const body = JSON.stringify({email, password});
    await axios.post(JWT_CREATE_ENDPOINT, body, getJSONConfig())
        .then(response => {
            dispatch({
                type: LOGIN_SUCCESS, payload: response.data
            });
            dispatch(load_user());
        })
        .catch(error => {
            dispatch({
                type: LOGIN_FAIL
            });
            throw error;
        });
};

export const signup = (first_name, last_name, email, password, re_password) => async dispatch => {
    const body = JSON.stringify({first_name, last_name, email, password, re_password});
    await axios.post(AUTH_SIGNUP_ENDPOINT, body, getJSONConfig())
        .then(response => {
            dispatch({
                type: SIGNUP_SUCCESS, payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: SIGNUP_FAIL
            });
            throw error;
        });
};

export const activation = (uid, token) => async dispatch => {
    const body = JSON.stringify({uid, token});
    await axios.post(AUTH_ACTIVATION_ENDPOINT, body, getJSONConfig())
        .then(() => {
            dispatch({
                type: ACTIVATION_SUCCESS
            });
        })
        .catch(() => {
            dispatch({
                type: ACTIVATION_FAIL
            });
        });
};

export const resend_activation = (email) => async dispatch => {
    const body = JSON.stringify({email});
    await axios.post(AUTH_RESEND_ACTIVATION_ENDPOINT, body, getJSONConfig())
        .then(() => {
            dispatch({
                type: ACTIVATION_SUCCESS
            });
        })
        .catch(() => {
            dispatch({
                type: ACTIVATION_FAIL
            });
        });
};

export const reset_password = (email) => async dispatch => {
    const body = JSON.stringify({email});
    await axios.post(AUTH_RESET_PASSWORD_ENDPOINT, body, getJSONConfig())
        .then(() => {
            dispatch({
                type: PASSWORD_RESET_SUCCESS
            });
        })
        .catch(() => {
            dispatch({
                type: PASSWORD_RESET_FAIL
            });
        });
};

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const body = JSON.stringify({uid, token, new_password, re_new_password});
    await axios.post(AUTH_RESET_PASSWORD_CONFIRM_ENDPOINT, body, getJSONConfig())
        .then(() => {
            dispatch({
                type: PASSWORD_RESET_CONFIRM_SUCCESS
            });
        })
        .catch(error => {
            dispatch({
                type: PASSWORD_RESET_CONFIRM_FAIL
            });
            throw error;
        });
};

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
};
