import React, {useEffect, useRef, useState} from 'react';
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import {Accordion} from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import {Popover} from '@gravity-ui/uikit';
import {CircleInfo} from '@gravity-ui/icons';

function BmmMarkdown({markdown_2, images}) {

    const footnotes = useRef({});

    const [strippedMarkdown, setStrippedMarkdown] = useState('');
    const [accordionContent, setAccordionContent] = useState([]);


    useEffect(() => {
        // Формируем markdown, содержащий только заголовки h3
        let stripped = markdown_2;
        let sections = markdown_2.split(/(?=###### )/);

        const result = sections.map(section => {
            // Извлекаем заголовок h6 и текст под ним
            const matches = section.match(/(###### .+?)(?=(\n#|$))/s);
            return matches ? matches[0] : null;
        }).filter(Boolean).join('\n\n');

        sections = result.split(/(?=###### )/);

        const parsedContent = sections.map(section => {
            // Разбиваем секцию на заголовок и контент
            const [titleLine, ...contentLines] = section.split('\n');
            const title = titleLine.replace('###### ', '').trim();
            const content = contentLines.join('\n').trim();

            // удаляем content из stripped
            stripped = stripped.replace(content, '');

            return {title, content};
        });

        setAccordionContent(parsedContent);
        setStrippedMarkdown(stripped);
    }, [markdown_2]);

    const CarouselRenderer = () => (
        <Carousel interval={null}>
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <img className="carousel-img" src={image} alt={`slide ${index}`}/>
                </Carousel.Item>
            ))}
        </Carousel>
    );

    const handleParagraph = ({node, children, ...props}) => {
        if (children && children.length > 0 && children === '[Carousel]') {
            return <CarouselRenderer/>;
        }
        return <div className="bmm-markdown-p" {...props}>{children}</div>;
    };

    const handleHeading6 = ({children}) => {
        const title = children;

        return (
            <Accordion className="about-accordion mt-4" alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h3 className="bmm-subtitle m-0">{title}</h3>
                    </Accordion.Header>
                    <Accordion.Body className="bmm-text">
                        <Markdown remarkPlugins={[remarkGfm]}
                                  components={{
                                      p: handleParagraph,
                                      sup: handleSup,
                                      section: handleSection,
                                  }}>{accordionContent.find(item => item.title === title)?.content}</Markdown>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };

    const handleSup = ({children}) => {
        const identifier = children?.props?.href?.replace('#user-content-fn-', '');

        if (!identifier || !footnotes.current[identifier]) {
            return null;
        }

        return (
            <span>
            <Popover
                autoclosable={true}
                contentClassName="bmm-popover-l"
                placement="auto"
                content={footnotes.current[identifier]}
            >
                <CircleInfo className="info-icon"/>
            </Popover>
        </span>
        );
    };

    const handleSection = ({node, children}) => {
        if (node?.properties?.dataFootnotes) {
            const olChild = children.find(child => child.type === 'ol');
            if (olChild) {
                olChild.props.children.forEach((listItem, index) => {

                    const identifier = listItem?.props?.id?.replace('user-content-fn-', '');

                    const footnoteContent = listItem?.props?.children;

                    if (identifier && footnoteContent) {
                        footnotes.current[identifier] = footnoteContent;
                    }
                });
            }
            return null;
        }
        return <section>{children}</section>;
    };


    return (
        <>
            <Markdown remarkPlugins={[remarkGfm]} className={"bmm-markdown-text"}
                      components={{
                          p: handleParagraph,
                          h6: handleHeading6,
                          sup: handleSup,
                          section: handleSection,
                      }}>{strippedMarkdown}</Markdown>
        </>
    );
}

export default BmmMarkdown;