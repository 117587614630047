import React, {useEffect, useState} from 'react'
import axios from "axios";

import {
    getAcceptAuthConfig,
    PARTICIPANT_PROJECTS_ENDPOINT, REFLECTIONS_WORKER_ENDPOINT, USER_APPLICATIONS_ENDPOINT
} from "../../../api/api";
import ProjectWorkerCard from "../../../components/projectCard/ProjectWorkerCard";
import {Accordion} from "react-bootstrap";

function ProjectParticipantList(props) {
    const [projects, setProjects] = useState([]);
    const [notActiveProjects, setNotActiveProjects] = useState([]);

    const [templates, setTemplates] = useState(null);
    const [userFiles, setUserFiles] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const pr_temp = null;
        axios
            .get(PARTICIPANT_PROJECTS_ENDPOINT, {
                params: {is_active: "True"},
                headers: getAcceptAuthConfig().headers,
            })
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting my projects:', error);
            });

        axios
            .get(PARTICIPANT_PROJECTS_ENDPOINT, {
                params: {is_active: "False"},
                headers: getAcceptAuthConfig().headers,
            })
            .then(response => {
                setNotActiveProjects(response.data);
            })
            .catch(error => {
                console.error('Error getting my projects:', error);
            });

        axios
            .get(REFLECTIONS_WORKER_ENDPOINT, getAcceptAuthConfig())
            .then(response => {
                const templates = response.data.templates;
                const filteredTemplates = templates.filter(template => template.type_file.startsWith('worker_'));
                setTemplates(filteredTemplates);
                setUserFiles(response.data.user_files);
            })
            .catch(error => {
                console.error('Ошибка при получении шаблонов и файлов:', error);
            });

    }, []);

    return (
        <>
            <h3 className="mb-2">Мои проекты</h3>
            <hr className="clearfix w-100 pb-0"/>

            {projects.reverse().map((project) => (
                <ProjectWorkerCard key={project.id} project={project}
                                   templates={templates} userFiles={userFiles} setUserFiles={setUserFiles}
                                   submitting={submitting} setSubmitting={setSubmitting}/>))}

            {notActiveProjects.length > 0 &&
                <Accordion className="about-accordion" alwaysOpen>
                    <Accordion.Item eventKey={`archive`}>
                        <Accordion.Header>
                            <h4 className="m-0">Архив</h4>
                        </Accordion.Header>
                        <Accordion.Body className="local-workshop-list">
                            {notActiveProjects.map((project, index) => (
                                <ProjectWorkerCard key={index}
                                                   project={project}
                                                   is_active={false}
                                />
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }
        </>
    );
}

export default ProjectParticipantList;