import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Accordion, Form} from "react-bootstrap";
import {Button, User, UserLabel, Avatar} from "@gravity-ui/uikit";
import {CircleCheck, ClockArrowRotateLeft, Pin, TextAlignLeft} from "@gravity-ui/icons";

import {
    getAcceptAuthConfig,
    getJSONConfig,
    WORKSHOP_INFO_ENDPOINT,
    WORKSHOPS_INFO_ENDPOINT
} from "../../../api/api";
import {
    AddButtonMaxWidth,
    DeleteButtonIcon_span,
    EditButtonIcon_span,
    StartButton
} from "../../../components/Button/Button";
import {ConfirmModal, DeleteModal, UpdateModal, WorkshopDeadlineModal} from "../../../components/Modal/Modals";
import {AsyncSelectField} from "../ProjectFields";
import LoadingSpinner from "../../../components/LoadingSpinner";
import UnderDevelopment from "../../../utils/utils";
import {ProjectDate, ProjectPersona} from "../../../components/projectCard/utils";


const WorkshopForm = ({
                          submitting,
                          onSubmit,
                          workshopData,
                          setWorkshopData,
                          errors,
                          setOpen
                      }) => {
    const onChange = (e, dataField) => {
        const value = e?.value || e?.target?.value || "";
        setWorkshopData((prevData) => ({
            ...prevData,
            [dataField]: value,
        }));
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group controlId="name">
                <Form.Label className="required">Название мастерской</Form.Label>
                <Form.Control required
                              type="text"
                              name="name"
                              value={workshopData.name}
                              onChange={(e) => onChange(e, 'name')}
                              autoComplete="off"
                              isInvalid={!!(errors && errors.name)}/>
            </Form.Group>
            <Form.Group controlId="year">
                <Form.Label className={"required"}>Год</Form.Label>
                <Form.Control required
                              name="year"
                              type="number"
                              onChange={(e) => onChange(e, 'year')}
                              value={workshopData.year}/>
            </Form.Group>
            <AsyncSelectField required={false}
                              controlId="formLocalWorkshopDirector"
                              label="Руководитель мастерской"
                              name="director"
                              value={workshopData.director?.email
                                  ? [{
                                      label: workshopData.director.email,
                                      value: workshopData.director.email
                                  }]
                                  : workshopData.director
                                      ? [{
                                          label: workshopData.director,
                                          value: workshopData.director
                                      }]
                                      : ""}
                              onChange={onChange}
                              errors={errors}
            />
            <div className="line-btn-group">
                <Button view="normal" onClick={() => setOpen(false)} width="max">Отменить</Button>
                <Button view="action" type="submit" disabled={submitting} width="max">
                    {submitting ? "Сохранение..." : "Сохранить"}
                </Button>
            </div>
        </Form>
    );
};

const WorkshopFormUpdate = ({workshop, setOpenUpdate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [workshopData, setWorkshopData] = useState({
        name: workshop.name,
        year: workshop.year,
        is_active: workshop.is_active,
        director: workshop.director?.email,
    });
    const [errors, setErrors] = useState({
        name: "",
        year: "",
        is_active: "",
        director: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .patch(WORKSHOP_INFO_ENDPOINT(workshop.id), workshopData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error updating workshop data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <WorkshopForm submitting={submitting} onSubmit={onSubmit}
                      workshopData={workshopData} setWorkshopData={setWorkshopData}
                      errors={errors}
                      setOpen={setOpenUpdate}/>
    );
};

const WorkshopFormCreate = ({setOpenCreate}) => {
    const [submitting, setSubmitting] = useState(false);

    const [workshopData, setWorkshopData] = useState({
        name: "",
        year: "",
        is_active: false,
        director: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        year: "",
        is_active: "",
        director: "",
    });

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        axios
            .post(WORKSHOPS_INFO_ENDPOINT, workshopData, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                setErrors(JSON.parse(error.response.request.response))
                console.error('Error creating venue data:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <WorkshopForm submitting={submitting} onSubmit={onSubmit} setOpen={setOpenCreate}
                      workshopData={workshopData} setWorkshopData={setWorkshopData} errors={errors}/>
    );
};

const Workshop = ({workshop, eventKey}) => {
    const [openCreate, setOpenCreate] = useState(false);

    const [openMakeActive, setOpenMakeActive] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openDeadlines, setOpenDeadlines] = useState(false);

    const onSetActive = () => {
        axios
            .patch(WORKSHOP_INFO_ENDPOINT(workshop.id), {is_active: true}, getAcceptAuthConfig())
            .then(response => {
                window.location.reload();
            })
    }

    const onDelete = async (workshopId) => {
        try {
            await axios.delete(WORKSHOP_INFO_ENDPOINT(workshopId), getAcceptAuthConfig());
            window.location.reload();
        } catch (error) {
            window.location.reload();
            console.error('Ошибка при удалении мастерской:', error);
        }
    };

    return (
        <Accordion.Item eventKey={`${eventKey}`}>
            <Accordion.Header>
                <div className={"d-flex justify-content-between align-items-center w-100"}>
                    <div className={"d-flex align-items-center"}>
                        {workshop.director && <Avatar className={"me-1"} imgUrl={workshop.director?.avatar}/>}
                        <h5 className="m-0">{workshop.name} {workshop.year}</h5>
                    </div>
                    <div className="line-btn-group me-10">
                        <DeleteButtonIcon_span onClick={(e) => {
                            // т. к. кнопки находятся внутри Accordion, который сам является кнопкой...
                            e.stopPropagation(); // Остановить всплытие события
                            setOpenDelete(true);
                        }}/>
                        <EditButtonIcon_span onClick={(e) => {
                            e.stopPropagation(); // Предотвращает раскрытие Accordion
                            setOpenUpdate(true);
                        }}/>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body className="local-workshop-list">
                {eventKey > 0 && (
                    <StartButton onClick={() => setOpenMakeActive(true)}
                                 view="outlined-danger"
                                 icon={Pin}
                                 text="Сделать эту мастерскую активной"/>
                )}
                <StartButton to={`/about/${workshop.id}`}
                             icon={TextAlignLeft}
                             text="Редактировать описание мастерской"/>
                <StartButton to={`stages/${workshop.id}`}
                             icon={CircleCheck}
                             text="Редактировать этапы мастерской"/>
                    <StartButton onClick={() => setOpenDeadlines(true)}
                        icon={ClockArrowRotateLeft}
                        text="Редактировать показываемые кнопки"
                    />
                {/*<div className={"d-flex align-items-center justify-content-between mt-2"}>*/}
                {/*    <span>{workshop.director && <ProjectPersona user={workshop.director}/>}</span>*/}
                {/*    <div className="project-card__subtitle-date">*/}
                {/*        {workshop.year}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <ConfirmModal open={openMakeActive} setOpen={setOpenMakeActive}
                              text={`Вы действительно хотите сменить активную мастерскую на ${workshop.name}? 
                              После этого на страницах "О мастерской", "Проекты", "Этапы", 
                              а также "Управление проектами", "Заявки на участие", "Распределение", "Тревел гранты" 
                              будет отображаться информация именно этой мастерской.`}
                              onClick={onSetActive}/>
                <DeleteModal type="мастерскую" name={`${workshop.name}`}
                             onDelete={() => onDelete(workshop.id)}
                             open={openDelete} setOpen={setOpenDelete}/>
                <UpdateModal open={openUpdate} setOpen={setOpenUpdate}>
                    <WorkshopFormUpdate workshop={workshop} setOpenUpdate={setOpenUpdate}/>
                </UpdateModal>
                <WorkshopDeadlineModal open={openDeadlines} setOpen={setOpenDeadlines} workshop_id={workshop.id}/>
            </Accordion.Body>
        </Accordion.Item>
    );
};

function WorkshopReviewList() {
    const [activeWorkshop, setActiveWorkshop] = React.useState(null);
    const [notActiveWorkshops, setNotActiveWorkshops] = React.useState([]);
    const [loading, setLoading] = useState(true);

    const [openCreate, setOpenCreate] = useState(false);

    useEffect(() => {
        // Запуск всех запросов параллельно
        const fetchActiveWorkshops = axios.get(WORKSHOPS_INFO_ENDPOINT, {
            params: {is_active: "True"},
            headers: getJSONConfig().headers,
        });
        const fetchNotActiveWorkshops = axios.get(WORKSHOPS_INFO_ENDPOINT, {
            params: {is_active: "False"},
            headers: getJSONConfig().headers,
        });

        // Ожидаем завершения всех запросов
        Promise.all([fetchActiveWorkshops, fetchNotActiveWorkshops])
            .then(([activeRes, notActiveRes]) => {
                if (activeRes.data.length > 0) {
                    setActiveWorkshop(activeRes.data[0]);
                }
                setNotActiveWorkshops(notActiveRes.data);
            })
            .catch((error) => {
                console.error("Error loading workshops data:", error);
            })
            .finally(() => {
                setLoading(false); // Устанавливаем `loading` в false после завершения запросов
            });
    }, []);

    // Отображение загрузочного индикатора
    if (loading) {
        return <LoadingSpinner/>;
    }

    // Основной рендер
    return (
        <>
            <h3 className="mb-2">Мастерские</h3>
            <hr className="clearfix w-100 pb-0"/>

            <AddButtonMaxWidth text={"Добавить мастерскую"} onClick={() => setOpenCreate(true)}/>
            <UpdateModal open={openCreate} setOpen={setOpenCreate}>
                <WorkshopFormCreate setOpenCreate={setOpenCreate}/>
            </UpdateModal>

            <Accordion className="about-accordion mt-3"
                       alwaysOpen
                       defaultActiveKey={['0']}>
                {activeWorkshop && <Workshop workshop={activeWorkshop} eventKey={0}/>}
                {notActiveWorkshops.reverse().map((workshop, index) => (
                    <Workshop key={workshop.id} workshop={workshop} eventKey={index + 1}/>
                ))}
            </Accordion>
        </>
    );
}

export default WorkshopReviewList;